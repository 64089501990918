import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { CreateStripeCheckoutSessionRequest, DeploymentType } from '../components/common/Types';
import { createStripeCheckoutSession } from '../lib/api/LicenseManagementApi';

export const useCreateStripeCheckoutSession = (
  onSuccessCallback?: () => void,
  onErrorCallback?: (error: unknown) => void
): UseMutationResult<
  unknown,
  Error,
  {
    aiphoneBranchPublicId: string;
    deploymentId: string;
    deploymentType: DeploymentType;
    data: CreateStripeCheckoutSessionRequest;
  }
> => {
  return useMutation({
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, deploymentType, data }) => {
      return await createStripeCheckoutSession(aiphoneBranchPublicId, deploymentId, deploymentType, data);
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  });
};
