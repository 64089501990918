import React from 'react';
import { FieldProps } from 'formik';
import { TextField, InputAdornment } from '@mui/material';
import { PhoneIphone } from '@mui/icons-material';
import { formatPhoneNumber } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';

interface PhoneInputProps extends FieldProps {
  label?: string;
  placeholder?: string;
  maxDigits?: number; // Limit for phone number digits
}

const PhoneField: React.FC<PhoneInputProps> = ({
  field, // Formik field (name, value, onChange)
  form, // Formik state (errors, touched, etc.)
  label,
  placeholder = '+1 (___) ___-____',
  maxDigits = 10, // Default max digits for phone numbers
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length === 0 || value.length === 1) {
      field.onChange(event);
      // Set to +1. Previously, there would be no way to enter digits.
      await form.setFieldValue(field.name, '+1');
      await form.setTouched({ ...form.touched, [field.name]: true });
      return;
    }

    const onlyNumbers = value.replace(/\D/g, ''); // Keep only numbers

    // Restrict input to max digits
    if (onlyNumbers.length > maxDigits) return;

    // Format the value into "pretty format".
    const formattedValue = formatPhoneNumber(onlyNumbers);
    // Call Formik's default handleChange method
    field.onChange(event); // or use `props.handleChange(event)`

    // Manually set the field value to the formatted version
    await form.setFieldValue(field.name, formattedValue);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <TextField
      {...field} // Formik field props
      {...props} // Additional props (e.g., fullWidth, size)
      label={label || t('Phone_Number')}
      placeholder={placeholder}
      onChange={handleChange}
      value={field.value ?? '+1'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneIphone />
          </InputAdornment>
        )
      }}
      error={form.touched[field.name] && Boolean(form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
    />
  );
};

export default PhoneField;
