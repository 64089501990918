import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ACLSite,
  initialACLSiteState,
  SimBillingSite,
  GwAndSim,
  GWInfo,
  GwAndSimRes
} from 'features/SimBilling/Types/SimBillingTypes';
import { convertJsonToAclSites, handleError, isValidAclUserType } from 'features/SimBilling/Utils/SimBillingUtils';
import { GetSiteJSON, GetGWInfo } from 'shared/api/Acl/IxgAclApi';
import { ErrorType } from 'shared/api/ApiError';
import {
  processACLSite,
  getACLSitesJSONContent,
  deviceDetailsUpdate,
  getSiteSubscriptionStatus,
  saveSiteId
} from 'shared/api/Aws/awsApi';
import { GwDetailsPayloadType } from '../Components/Entrypoints/Gwdevices/GWDevices.types';

export const fetchAclSitesData = createAsyncThunk<
  ACLSite[],
  { aclToken: string },
  { rejectValue: { errorMessage: ErrorType } }
>('aclSitesData/fetchSiteData', async (params, { rejectWithValue }) => {
  try {
    const json = await GetSiteJSON(params.aclToken);
    const payload = {
      jsonName: json,
      env: process.env.REACT_APP_STAGE ?? 'dev1'
    };
    const response = await getACLSitesJSONContent(payload);
    if (!response?.data) {
      return rejectWithValue({ errorMessage: ErrorType.SERVER });
    }
    let aclSites = convertJsonToAclSites(response.data);

    const aclSitesIds = aclSites.map((site) => site.PropertyID);
    const subscriptionStatuses = await getSiteSubscriptionStatus(aclSitesIds);

    // Merge subscription status into aclSites
    aclSites = aclSites.map((site) => {
      const subscriptionStatus = subscriptionStatuses?.data.find((status) => status.acl_site_id === site.PropertyID);
      return {
        ...site,
        SubscriptionStatus: subscriptionStatus ? subscriptionStatus.subscription_status : undefined // or any default value
      };
    });
    return aclSites;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const updateGwDeviceDetails = createAsyncThunk<
  { details: GwDetailsPayloadType; aclSiteId: number },
  { details: GwDetailsPayloadType; aclSiteId: number },
  { rejectValue: { errorMessage: ErrorType } }
>('aclSites/updateSiteDetails', async ({ details, aclSiteId }, { rejectWithValue }) => {
  try {
    await deviceDetailsUpdate(details);
    return { details, aclSiteId };
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

/**Make API requests to add the SiteId to Device ID column in the AT&T platform.*/
const saveSiteIdToATT = async (siteId: number, iccid: string): Promise<string | null> => {
  try {
    const params = {
      iccid,
      action: 'addSiteId',
      deviceID: siteId
    };

    const res = await saveSiteId(params);

    if (!res?.data?.iccid) {
      throw new Error();
    }

    return res.data.iccid;
  } catch (error) {
    return null;
  }
};

const attSynchronization = async (siteDetails: SimBillingSite) => {
  try {
    const aclSiteId = siteDetails.AclSiteId;
    const notSynchronizedDevices: GwAndSim[] = siteDetails.GwAndSim.filter((item: GwAndSim) => !item.IsSynchronized);

    const promises = notSynchronizedDevices.map(async (device) => {
      const deviceICCID = device.Iccid;

      /**ICCID can be null. Make API request only if ICCID is defined.*/
      if (deviceICCID) {
        const payload: string | null = await saveSiteIdToATT(aclSiteId, deviceICCID);

        /**Ensure that the Site ID has been saved to the AT&T platform, and only then proceed to update it in our database.*/
        if (payload) {
          const details = {
            ...device,
            AclSitePublicId: siteDetails.PublicId,
            IsSynchronized: true
          };
          await deviceDetailsUpdate(details);
        }
      }
    });

    return await Promise.all(promises);
  } catch (error) {
    // absorb error
  }
};

export const processAndFetchSitesDetails = createAsyncThunk<
  { siteId: number; details: SimBillingSite },
  { aclToken: string; siteId: number; site: ACLSite },
  { rejectValue: { errorMessage: ErrorType } }
>('aclSites/processAndFetchSitesDetails', async (params, { rejectWithValue }) => {
  try {
    const gatewayList = await GetGWInfo(params.aclToken, params.siteId);

    const transformedGWList = gatewayList.Data.GWList.map((gw: GWInfo) => ({
      ...gw,
      ICCID: gw.ICCID === '-' ? null : gw.ICCID
    }));

    const { SubscriptionStatus: _SubscriptionStatus, ...restOfSite } = params.site;
    const siteData = {
      ...restOfSite,
      GWList: transformedGWList
    };

    const payload = {
      siteData: {
        aclSites: [siteData]
      }
    };
    const response = await processACLSite(payload);

    if (!response?.data?.sites?.length) {
      return rejectWithValue({ errorMessage: ErrorType.SERVER });
    }

    const site = response.data.sites[0];
    if (site.gwAndSim && site.gwAndSim.some((gw: { message: string }) => gw?.message)) {
      const errorGw = site.gwAndSim.find((gw: { message: string }) => gw?.message);
      if (errorGw) {
        return rejectWithValue({ errorMessage: ErrorType.SERVER_DB });
      }
    }

    const transformedSiteData: SimBillingSite = {
      AclSiteId: site.acl_site_id,
      PublicId: site.public_id,
      SiteName: site.site_name,
      RemoteManagementSitePublicId: site.remote_management_site_public_id ?? null,
      Billing: site.billing ?? null,
      ServiceStatus: site.service_status ?? null,
      ContractPeriod: site.contract_period ?? null,
      Address1: site.address_1,
      Address2: site.address_2,
      Address3: site.address_3,
      City: site.city,
      ZipCode: site.zip_code,
      State: site.state,
      CountryCode: site.country_code,
      PhoneNumber: site.phone_number,
      PhoneExtension: site.phone_extension ?? null,
      Payer: isValidAclUserType(site.payer) ? site.payer : 'Dealer',
      SubscriptionId: site.stripe_subscription_id ?? null,
      SubscriptionCreatedByAclUserPublicId: site.subscription_created_by_acl_user ?? null,
      StripePriceId: site.stripe_price_id ?? null,
      SubscriptionStatus: site.subscription_status ?? null,
      SubscriptionEndDate: site.subscription_data_end_date ?? null,
      AdditionalData: Number(site.additional_data) ?? 0,
      SubscriptionData: Number(site.subscription_data) ?? 0,
      GwAndSim: site.gwAndSim
        ? site.gwAndSim.map(
            (gw: GwAndSimRes): GwAndSim => ({
              PublicId: gw.public_id,
              InUse: gw.in_use ?? null,
              Carrier: gw.carrier,
              Iccid: gw.iccid,
              SimStatus: gw.sim_status,
              GwClientNo: gw.gw_client_no,
              GwClientName: gw.gw_client_name,
              GwMacAddress: gw.gw_mac_address,
              SimActivationDate: null,
              AssignedPhoneNumber: null,
              DataCalculatedMessageId: null,
              Msisdn: null, //Will add the value in the GWDevices component.
              CurrentDataUsage: gw.current_data_usage ?? 0,
              IsSynchronized: gw.is_synchronized
            })
          )
        : [],
      CurrentDataUsage: site.gwAndSim.reduce((total: any, item: any) => total + item.current_data_usage, 0)
    };
    await attSynchronization(transformedSiteData);

    return { siteId: site.acl_site_id, details: transformedSiteData };
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

const aclSitesSlice = createSlice({
  name: 'aclSites',
  initialState: initialACLSiteState,
  reducers: {
    resetAclSites: (state) => {
      state.sites = undefined;
    },
    resetAclSiteError: (state) => {
      state.error.fetchAclSites = undefined;
      state.error.processAndFetchSitesDetails = undefined;
    },
    selectAclSite: (state, action: PayloadAction<number>) => {
      if (state.sites) {
        state.selectedSite = state.sites.find((site) => site.PropertyID === action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAclSitesData.pending, (state) => {
        state.loading.fetchAclSites = 'loading';
      })
      .addCase(fetchAclSitesData.fulfilled, (state, action) => {
        state.loading.fetchAclSites = 'loaded';
        state.sites = action.payload;
      })
      .addCase(fetchAclSitesData.rejected, (state, action) => {
        state.loading.fetchAclSites = 'failed';
        state.error.fetchAclSites = action.payload?.errorMessage || action.error.message;
      })
      .addCase(processAndFetchSitesDetails.pending, (state) => {
        state.loading.processAndFetchSitesDetails = 'loading';
      })
      .addCase(processAndFetchSitesDetails.fulfilled, (state, action) => {
        state.loading.processAndFetchSitesDetails = 'loaded';
        const { siteId, details } = action.payload;
        state.sitesDetails = {
          ...state.sitesDetails,
          [siteId]: details
        };
      })
      .addCase(processAndFetchSitesDetails.rejected, (state, action) => {
        state.loading.processAndFetchSitesDetails = 'failed';
        state.error.processAndFetchSitesDetails = action.payload?.errorMessage || action.error.message;
      })
      .addCase(updateGwDeviceDetails.pending, (state) => {
        state.loading.processAndFetchSitesDetails = 'loading';
      })
      .addCase(updateGwDeviceDetails.fulfilled, (state, action) => {
        state.loading.processAndFetchSitesDetails = 'loaded';
        const { details, aclSiteId } = action.payload;
        const { AclSitePublicId: _AclSitePublicId, ...updatedDetailsItem } = details;
        const GwAndSimItems = state.sitesDetails[aclSiteId].GwAndSim;
        const updatedItemIndex = GwAndSimItems.findIndex((device) => device.PublicId === details.PublicId);
        const newGwAndSim = [
          ...GwAndSimItems.slice(0, updatedItemIndex),
          updatedDetailsItem,
          ...GwAndSimItems.slice(updatedItemIndex + 1)
        ];
        state.sitesDetails = {
          ...state.sitesDetails,
          [aclSiteId]: {
            ...state.sitesDetails[aclSiteId],
            GwAndSim: newGwAndSim
          }
        };
      })
      .addCase(updateGwDeviceDetails.rejected, (state, action) => {
        state.loading.processAndFetchSitesDetails = 'failed';
        state.error.processAndFetchSitesDetails = action.payload?.errorMessage || action.error.message;
      });
  }
});

export const { resetAclSites, resetAclSiteError, selectAclSite } = aclSitesSlice.actions;
export const aclSitesReducer = aclSitesSlice.reducer;
