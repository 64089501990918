import { Step, StepLabel, Stepper } from '@mui/material';

interface DeploymentStepsProps {
  steps: string[];
  activeStep: number;
}
export const DeploymentSteps: React.FC<DeploymentStepsProps> = ({ steps, activeStep }) => (
  <Stepper activeStep={activeStep} sx={styles.stepper} orientation="horizontal">
    {steps.map((label, index) => (
      <Step key={label} active={index === activeStep} completed={index < activeStep}>
        <StepLabel sx={styles.stepLabel}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

const styles = {
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  stepper: {
    width: '100%',
    marginTop: '30px',
    flexDirection: 'row'
  }
};
