import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// #region import LicenseManagement
// Components
// Hooks
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { useNavigate } from 'react-router-dom';
import { useCreateStripeCheckoutSession } from 'features/LicenseManagement/hooks/useCreateStripeCheckoutSession';
import { useCreateActivatedLicenseWithCard } from 'features/LicenseManagement/hooks/useLicense';
// SharedComponents
import { EnumList, fetchEnumList, ICountryValue, IStateValue } from 'shared/utils/EnumUtils';
import { handleBack } from 'features/LicenseManagement/components/common/navigationUtils';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import {
  CreateActivatedLicenseWithCardRequest,
  DeploymentDetailResponse,
  DeploymentType,
  PaymentMethod,
  Product,
  ValidateRequestKeyRequest
} from 'features/LicenseManagement/components/common/Types';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { useValidateRequestKey } from 'features/LicenseManagement/hooks/useValidateRequestKey';
// #endregion

interface ActivationStepProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  accountNumber: number;
  selectedProduct: Product;
  deployment: DeploymentDetailResponse;
  selectedPaymentMethod: PaymentMethod;
  licenseCode: string;
}

const ActivationStep: React.FC<ActivationStepProps> = ({
  activeStep,
  setActiveStep,
  accountNumber,
  selectedProduct,
  deployment,
  selectedPaymentMethod,
  licenseCode
}) => {
  const { t } = useTranslation('licenseManagement');
  const [requestKey, setRequestKey] = useState('');
  const [isRequestKeyValid, setIsRequestKeyValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiError, setApiError] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const navigate = useNavigate();

  const { currentUser } = useReduxUsers();
  const { currentBranch } = useReduxBranches();

  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const deploymentId = deployment.deploymentId;
  const aiphoneBranchPublicId = deployment.aiphoneBranchPublicId ?? '';
  const s3ImageUrls = [
    `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/LicenseInfo.png`,
    `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ACNioSoftware.png`
  ];

  const { mutateAsync: isValidRequestKey, isPending: isPendingValidationRequestKey, error } = useValidateRequestKey();

  if (error) {
    setApiError(error.message || t('Error_Validating_Request_Key'));
    setErrorStatus(true);
  }

  // Mutation hooks

  const { mutate: createStripeCheckoutSession, isPending: isPendingCreateStripeSession } =
    useCreateStripeCheckoutSession(
      () => {
        setErrorStatus(false);
        setApiError('');
      },
      (error: any) => {
        setErrorStatus(true);
        setApiError(error.message || t('Error_Creating_Checkout_Session'));
        setTimeout(() => setErrorStatus(false), 3000);
      }
    );

  const { mutate: activateLicenseWithCard, isPending: isActivatingLicense } = useCreateActivatedLicenseWithCard(
    () => {
      setActiveStep((prevStep) => prevStep + 1);
      navigate(`/licensemanagement/${deploymentId}/activateLicense/responseKeyStep`);
    },
    (error: any) => {
      setErrorStatus(true);
      setApiError(error.message || t('Error_Creating_Activated_License'));
      setTimeout(() => setErrorStatus(false), 3000);
    }
  );

  const validateRequestKey = (key: string): boolean => /^[A-Z0-9]{5}(-[A-Z0-9]{5}){4}$/.test(key);

  const handleRequestKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.value.toUpperCase();
    setRequestKey(key);
    setIsRequestKeyValid(validateRequestKey(key));
  };

  const handleContinue = async () => {
    if (isRequestKeyValid && selectedProduct) {
      if (selectedPaymentMethod === PaymentMethod.Prepaid) {
        const request: CreateActivatedLicenseWithCardRequest = {
          hartmannDeploymentId: deployment.hartmannDeploymentId,
          hartmannBranchId: deployment.hartmannBranchId,
          packageId: selectedProduct.package,
          duration: selectedProduct.duration,
          requestKey,
          hartmannAccountNumber: accountNumber,
          licenseCode,
          licenseDescription: selectedProduct.description,
          maxDoorCount: selectedProduct.maxDoorCount
        };

        try {
          activateLicenseWithCard({
            aiphoneBranchPublicId: aiphoneBranchPublicId,
            deploymentId: deploymentId,
            data: request
          });
        } catch (error) {
          setApiError(t('Error_Creating_Activated_License'));
        }
      } else {
        try {
          // Step 1: Validate request key
          const validateRequestKeyRequest: ValidateRequestKeyRequest = {
            hartmannDeploymentId: deployment.hartmannDeploymentId,
            hartmannBranchId: deployment.hartmannBranchId,
            requestKey
          };

          const response = await isValidRequestKey({ aiphoneBranchPublicId, request: validateRequestKeyRequest });

          if (!response.isValid) {
            setApiError(t('Invalid_Request_Key'));
            setErrorStatus(true);
          }

          // Step 2: If request key is valid, create Stripe session
          if (response.isValid) {
            const state = enumList.state[currentBranch?.stateId ?? ''] as IStateValue;
            const country = enumList.country[state?.countryId ?? ''] as ICountryValue;

            const params = {
              priceId: selectedProduct.priceId,
              stripeCustomerId: currentUser?.stripeCustomerId ?? null,
              customerEmail: currentUser?.email ?? '',
              licenseStatus: deployment.licenseStatus,
              licenseExpirationDate: deployment.licenseExpirationDate,
              licenseStripeInvoiceId: deployment.licenseStripeInvoiceId,
              addressForTax: {
                line1: currentBranch?.address ?? '',
                city: currentBranch?.city ?? '',
                state: state.value,
                //If branch creation supports more countries in the future, this will have to be updated
                country: country.value === 'United States' || country.value == 'United States of America' ? 'US' : 'CA',
                postal_code: currentBranch?.postalCode ?? ''
              },
              taxExempt: false, // TODO: Fetch from DB
              cloudUserPublicId: currentUser?.publicId,
              reactAppStage: 'localhost', // TODO: Will change back to dev
              deploymentType: DeploymentType.Local,
              numOfDoors: selectedProduct.maxDoorCount,
              numOfCameras: selectedProduct.maxDoorCount,
              licenseName: selectedProduct.name,
              packageId: selectedProduct.package,
              duration: selectedProduct.duration,
              hartmannDeploymentId: deployment.hartmannDeploymentId,
              hartmannBranchId: deployment.hartmannBranchId,
              hartmannAccountNumber: accountNumber,
              requestKey: requestKey
            };

            createStripeCheckoutSession(
              {
                aiphoneBranchPublicId: deployment.aiphoneBranchPublicId ?? '',
                deploymentId: deployment.deploymentId ?? '',
                deploymentType: DeploymentType.Local,
                data: params
              },
              {
                onSuccess: (response: any) => {
                  if (response?.data?.message) {
                    window.location.href = response.data.message;
                  } else {
                    setErrorMessage(t('Error_Creating_Checkout_Session'));
                  }
                },
                onError: (error: any) => {
                  setErrorMessage(error.message || t('Error_Creating_Checkout_Session'));
                }
              }
            );
          }
        } catch (error) {
          setApiError(t('Error_Creating_Checkout_Session'));
        }
      }
    } else {
      setErrorMessage(t('Invalid_Request_Key'));
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(accountNumber.toString());
  };

  const isLoading = isPendingCreateStripeSession || isActivatingLicense || isPendingValidationRequestKey;

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.accountNumberText}>
        {t('Account_Number')} : <span style={styles.accountNumber}>{accountNumber}</span>
        <Tooltip title={t('Copy_To_Clipboard')}>
          <IconButton onClick={handleCopy} size="small" sx={styles.copyButton}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      {errorMessage && (
        <Typography color="error" sx={styles.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <Box sx={styles.contentSection}>
        <Box sx={styles.textSection}>
          <Typography variant="h6" gutterBottom>
            {t('Request_Key_Text')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('Get_Request_Key_Text')}
          </Typography>
          <Box component="img" src={s3ImageUrls[0]} alt="License Info" sx={styles.licenseInfoImage} />
          <Typography variant="h6" gutterBottom>
            {t('Response_Key_Text')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('Get_Response_Key_Text')}
          </Typography>
          <FormControl sx={styles.form}>
            <TextField
              id="request-key"
              placeholder={t('Enter_Request_Key')}
              variant="outlined"
              fullWidth
              value={requestKey}
              onChange={handleRequestKeyChange}
              error={!isRequestKeyValid && requestKey.length > 0}
              helperText={!isRequestKeyValid && requestKey.length > 0 ? t('Invalid_Request_Key') : ' '}
              FormHelperTextProps={{ sx: styles.helperText }}
            />
          </FormControl>
        </Box>

        <Box sx={styles.imageSection}>
          <img src={s3ImageUrls[1]} alt="AC Nio Software" style={styles.acNioSoftwareImage} />
        </Box>
      </Box>
      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button
          variant="text"
          sx={LicenseManagementCommonStyles.buttonBack}
          onClick={() => handleBack(activeStep, setActiveStep)}
        >
          {t('Back')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={handleContinue}
          disabled={!isRequestKeyValid}
        >
          {t('Continue_To_Payment')}
        </Button>
      </Box>
      {isLoading && <LoadingSpinner />}
      {errorStatus && apiError && (
        <ErrorAlert errorMessage={apiError} customMessage={t('Error_Creating_Checkout_Session')} />
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginTop: '20px',
    marginLeft: '25px'
  },
  accountNumberText: {
    padding: 1,
    marginTop: 2
  },
  accountNumber: {
    backgroundColor: '#f0f0f0',
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  },
  copyButton: {
    marginLeft: '8px'
  },
  contentSection: {
    flex: 1,
    display: 'flex'
  },
  textSection: {
    flex: 1,
    padding: 2
  },
  imageSection: {
    flex: 1,
    display: 'flex'
  },
  licenseInfoImage: {
    maxHeight: '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '20px'
  },
  acNioSoftwareImage: {
    width: '100%',
    height: '100%',
    maxHeight: '500px',
    marginTop: '50px'
  },
  form: {
    marginTop: '10px',
    width: '100%'
  },
  helperText: {
    minHeight: '1.5em'
  },
  errorMessage: {
    mb: 2
  }
};

export default ActivationStep;
