import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ValidateRequestKeyRequest, ValidateRequestKeyResponse } from '../components/common/Types';
import { validateRequestKey } from '../lib/api/LicenseManagementApi';

export const useValidateRequestKey = (): UseMutationResult<
  ValidateRequestKeyResponse,
  Error,
  { aiphoneBranchPublicId: string; request: ValidateRequestKeyRequest }
> => {
  return useMutation({
    mutationFn: async ({ aiphoneBranchPublicId, request }) => {
      const response: ValidateRequestKeyResponse = await validateRequestKey(aiphoneBranchPublicId, request);
      return response;
    }
  });
};
