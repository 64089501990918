import { useQuery, useQueryClient } from '@tanstack/react-query';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { fetchAiphoneToHartmannBranchMapping } from '../lib/api/LicenseManagementApi';
import { AiphoneBranchToHartmannBranchMapping } from '../components/common/Types';

export const useFetchAiphoneToHartmannBranchMapping = () => {
  const { currentBranch } = useReduxBranches();
  const { data, error, isLoading } = useQuery({
    queryKey: ['aiphoneBranchToHartmannBranchMapping', currentBranch?.publicId],
    queryFn: () => {
      if (!currentBranch?.publicId) {
        return Promise.reject(new Error('No branch public ID available'));
      }
      return fetchAiphoneToHartmannBranchMapping(currentBranch.publicId);
    },
    enabled: !!currentBranch?.publicId,
    staleTime: 0,
    retry: 3 // Retries are enabled for all errors except 404,
  });
  return { data, error, isLoading };
};

export const useReactQueryDataAiphoneToHartmannBranchMapping = () => {
  const { currentBranch } = useReduxBranches();
  const queryClient = useQueryClient();
  const cachedData: AiphoneBranchToHartmannBranchMapping | undefined = queryClient.getQueryData([
    'aiphoneBranchToHartmannBranchMapping',
    currentBranch?.publicId
  ]);
  if (!cachedData) {
    throw new Error('No cached data found');
  }
  return cachedData;
};
