import { Box, Typography, CircularProgress } from '@mui/material';
import { useUpdateDeviceMutation, useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { LoadingButton } from '@mui/lab';
import { updateSite } from 'shared/api/Aws/awsApi';
import { updateSite as updateSiteInStore } from 'store';
import { getSite } from 'store/slices/siteSlice';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useState } from 'react';
import StringUtils from 'shared/utils/StringUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  DEFAULT_IP_ADDRESS,
  DEFAULT_SUBNET_MASK,
  DEFAULT_IPV4_GATEWAY,
  DEFAULT_PRIMARY_DNS
} from 'shared/constants/ipaddresses';

export const InitializationLabel = () => {
  const initialization = getString('Initialization');
  return <span>{initialization}</span>;
};

const Initialization = ({ hasEditPermission }: { hasEditPermission: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const selectedDevice = useSelector(getSelectedDevice);
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const isGatewayFirstSync = gateway?.lastSyncedOn === null;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const needAssociation = selectedDevice?.networkSettings?.ipV4Address === DEFAULT_IP_ADDRESS;
  const [updateDevice] = useUpdateDeviceMutation();

  const initializeDevice = async () => {
    setIsLoading(true);
    // initialize the device
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;

    const deviceInfo = {
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceType: selectedDevice?.basicInfo?.deviceType,
      deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      devicePassword: systemPassword
        ? systemPassword
        : isDeviceFirstSync
        ? 'admin'
        : selectedDevice?.basicInfo.adminPass
    };
    try {
      if (!gateway) {
        throw new Error('Cannot complete request, gateway not registered');
      }

      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      if (ioTPayload === 'Missing information') {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();

      // create a timeout for 60 seconds
      await new Promise((resolve) => setTimeout(resolve, 60000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      if (fetchResponse?.statusCode.includes('206')) {
        fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      }

      const devicePayloadResponse = fetchResponse?.payload[0];
      const statusCode = devicePayloadResponse?.statusCode.slice(0, 3);
      if (statusCode === '402') {
        throw new Error('wrong id or password');
      }

      if (statusCode !== '200') {
        throw new Error('Error initializing device');
      }

      setIsLoading(false);
      setShowAlert(true);

      const updatedDeviceParams = {
        device: {
          publicId: selectedDevice?.publicId,
          networkSettings: {
            ...selectedDevice?.networkSettings,
            ipV4Address: DEFAULT_IP_ADDRESS,
            subnetMask: DEFAULT_SUBNET_MASK,
            ipV4DefaultGateway: DEFAULT_IPV4_GATEWAY,
            ipV4PrimaryDns: DEFAULT_PRIMARY_DNS
          },
          lastSyncedOn: null // TODO: this doesn't actually work, need to fix
        }
      };
      updateDevice(updatedDeviceParams);
    } catch (error) {
      console.log(error);
      if (error instanceof Error && error.message === 'wrong id or password') {
        setErrorMessage(t('Wrong_ID_or_Password'));
      } else {
        setErrorMessage(t('Error_Initialize'));
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);

      // regardless of result, clear the registered gateway public id, in case the device has been reset or is no longer working
      if (selectedDevice.publicId === site.siteInfo.registeredGatewayPublicId) {
        // if the device is the registered gateway, clear the registered gateway public id
        const updateSitePayload = {
          updatedSite: {
            publicId: site.siteInfo.publicId,
            registeredGatewayPublicId: null
          }
        };
        dispatch(updateSiteInStore(updateSitePayload));
        await updateSite(updateSitePayload);
      }
    }
  };

  return (
    <>
      <Typography variant="h5" color="text.primary" sx={{ paddingBottom: '5px' }}>
        {t('Initialize')}
      </Typography>

      {needAssociation ? (
        <Box sx={styles.gwContainer}>
          <Typography variant="h6">{t('Associate_Before_Initialize')}</Typography>
        </Box>
      ) : (
        <Box sx={styles.gwContainer}>
          <Box flexDirection={'row'} alignItems={'center'} sx={styles.buttonGWContainer}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading || !hasEditPermission}
              loadingIndicator={<CircularProgress size="20px" color="info" />}
            >
              <PublishedWithChangesIcon onClick={initializeDevice} />
            </LoadingButton>
            <Typography variant="h6">{t('Initialize_Default')}</Typography>
          </Box>
        </Box>
      )}

      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={StringUtils.format(getString('Update_Success'), getString('Site'))}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '20px',
    height: '100%'
  },
  title: {
    marginBottom: '10px',
    color: '#333',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  subtitle: {
    display: 'flex',
    color: '#666',
    padding: '10px'
  },
  icon: {
    fontSize: '5rem'
  },
  syncContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginY: '2rem'
  },
  gwContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  gwFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end'
  },
  buttonGWContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem',
    flexDirection: 'row',
    alignItems: 'center'
  },
  siteInfoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Initialization;
