import { Box, Typography } from '@mui/material';
import { useFetchPaymentInfo } from 'features/LicenseManagement/hooks/useFetchPaymentInfo';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../common/ErrorAlert';
import LoadingSpinner from '../../common/LoadingSpinner';
import FieldDisplay from '../../common/Fielddisplay';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

interface PaymentInformationProps {
  stripeSubscriptionId: string;
}

const PaymentInformation = ({ stripeSubscriptionId }: PaymentInformationProps) => {
  const { t } = useTranslation('licenseManagement');
  const masterCardIconImageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons8-mastercard(1).svg`;
  const visaCardIconImageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons8-visa.svg`;

  const { data, isLoading, isError, error, refetch } = useFetchPaymentInfo(stripeSubscriptionId);

  if (isError) {
    return (
      <Box>
        <ErrorAlert errorMessage={error?.message} customMessage={t('Error_Loading_Payment_Info')} onRetry={refetch} />
      </Box>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderField = (label: string, value: any, isLast?: boolean) => (
    <FieldDisplay label={t(label)} value={value} isLast={isLast} />
  );

  const renderPaymentMethod = () => {
    if (!data?.card) return '';

    const { brand, last4, expMonth, expYear } = data.card;
    //TODO: Add update payment url
    return (
      <Box sx={styles.container}>
        <Typography variant="body1">Credit Card</Typography>
        <Box sx={styles.textContainer}>
          {brand === 'visa' && <img src={visaCardIconImageUrl} alt="Visa" style={styles.cardIcon} />}
          {brand === 'mastercard' && <img src={masterCardIconImageUrl} alt="MasterCard" style={styles.cardIcon} />}
          <Typography>
            {`Card ending in ${last4}`} <span style={{ color: 'lightgray' }}>•</span> {`Expires ${expMonth}/${expYear}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderBillingAddress = () => {
    const { billingAddress } = data || {};
    if (!billingAddress) return '';

    const { line1, city, state, postalCode, country } = billingAddress;
    // Build the address string only with non-null values
    const addressParts = [line1, city, state, postalCode, country].filter((part) => part);
    return addressParts.join(', ');
  };

  return (
    <Box sx={styles.sectionStyle}>
      {renderField('Payment_Method', renderPaymentMethod())}
      {renderField('Billing_Address', renderBillingAddress())}
      {renderField('Billing_Email', data?.billingEmail, true)}
    </Box>
  );
};
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  text: {
    color: 'gray',
    fontStyle: 'italic',
    fontSize: '1.5rem',
    padding: '1rem'
  },
  cardIcon: {
    width: 40,
    height: 25
  }
};
export default PaymentInformation;
