import { useQuery } from '@tanstack/react-query';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { DeploymentType } from '../components/common/Types';
import { fetchStripeProducts } from '../lib/api/LicenseManagementApi';

const STALE_TIME = 60000;

export const useFetchStripeProducts = (countryId: string, deploymentType: DeploymentType) => {
  const enabled = !!countryId && deploymentType !== undefined && deploymentType !== null;

  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useQuery({
    queryKey: ['stripeProducts', countryId, deploymentType],
    queryFn: () => fetchStripeProducts(countryId, deploymentType, branchId ?? ''),
    enabled: enabled,
    staleTime: STALE_TIME
  });
};
