import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';

import { formatAmericanZipCode, formatCanadianPostalCode } from 'shared/utils/helperFunctions';

// NOTE: The parent component shall control this one.

type RegionCodeType = 'zip' | 'postal';

interface ZipPostalCodeInputFieldProps extends FieldProps {
  regionCodeType: RegionCodeType;
  label?: string;
  placeholder?: string;
  maxLength?: number; // Limit for phone number digits
}

const ZipPostalCodeField: React.FC<ZipPostalCodeInputFieldProps> = ({
  field,
  form,
  regionCodeType,
  label,
  placeholder,
  ...props
}) => {
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let formattedValue = '';
    if (regionCodeType === 'zip') {
      // Format and restrict the input text
      const onlyNumbers = value.replace(/\D/g, ''); // Keep only numbers

      // If it is a zipcode, then do not allow more text input
      if (onlyNumbers.length > 5) {
        return;
      }

      formattedValue = formatAmericanZipCode(onlyNumbers);
    } else {
      // Format and restrict the input text

      if (value.length > 7) {
        return;
      }

      formattedValue = formatCanadianPostalCode(value);
    }

    // Call Formik's default handleChange method
    // This is necessary for getting the top-level form validation to run
    field.onChange(event); // or use `props.handleChange(event)`

    // Manually set the field value to the formatted version
    await form.setFieldValue(field.name, formattedValue);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (regionCodeType === 'zip') {
      // Prevent 'e' and other non-numeric characters
      if (/[\D+e\-.]/.test(event.key)) {
        event.preventDefault();
      }
    } else {
      // Canadian
      // Permit letters (except D, F, I, O, Q, and U), digits and space
      if (!/[ABCEGHJKLMNPRSTVWXYZ0-9 ]/i.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <TextField
      {...field}
      {...props}
      value={field.value ?? ''}
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      error={form.touched[field.name] && Boolean(form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
    />
  );
};

export default ZipPostalCodeField;
