import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Description as LicenseIcon,
  Assignment as DeploymentIcon,
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  Receipt as ReceiptIcon
} from '@mui/icons-material';

const LicenseManagementSidebar = (props: any) => {
  const theme = useTheme();
  const location = useLocation();
  const { deploymentId } = useParams(); // Retrieve deploymentId from route parameters
  const { t } = useTranslation('licenseManagement');
  return (
    <Sidebar
      style={styles.sidebar}
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
      collapsedWidth={useMediaQuery(() => theme.breakpoints.up('sm')) ? '80px' : '0px'}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        <MenuItem
          active={location.pathname === `/licensemanagement/${deploymentId}/deploymentInformation`}
          component={<Link to={`/licensemanagement/${deploymentId}/deploymentInformation`} />}
          icon={<DeploymentIcon />}
        >
          <Typography variant="body2">{t('Deployment_Information')}</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === `/licensemanagement/${deploymentId}/licenseDetails`}
          component={<Link to={`/licensemanagement/${deploymentId}/licenseDetails`} />}
          icon={<LicenseIcon />}
        >
          <Typography variant="body2">{t('License_Details')}</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === `/licensemanagement/${deploymentId}/billingDetails`}
          component={<Link to={`/licensemanagement/${deploymentId}/billingDetails`} />}
          icon={<ReceiptIcon />}
        >
          <Typography variant="body2">{t('Deployment_Billing')}</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === `/licensemanagement/${deploymentId}/licenseHistory`}
          component={<Link to={`/licensemanagement/${deploymentId}/licenseHistory`} />}
          icon={<HistoryIcon />}
        >
          <Typography variant="body2">{t('License_History')}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Home')}
          </Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/licensemanagement'}
          component={<Link to="/licensemanagement" />}
          icon={<LicenseIcon />}
        >
          <Typography variant="body2">{t('License_Management')}</Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: 1
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    '@media (max-height: 700px)': {
      position: 'relative',
      zIndex: 1
    }
  }
};
export default LicenseManagementSidebar;
