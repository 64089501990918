/**
 * Auto-formatting email field
 */

import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';

interface EmailFieldProps extends FieldProps {
  label?: string;
  placeholder?: string;
}

const EmailField: React.FC<EmailFieldProps> = ({ field, label, placeholder, form, ...props }) => {
  const handleBlur = async () => {
    const trimmedValue = field.value.trim();
    const formattedValue = trimmedValue.toLowerCase();
    await form.setFieldValue(field.name, formattedValue);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    field.onChange(event);
    await form.setFieldValue(field.name, value);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <TextField
      {...field}
      {...props}
      type="email"
      label={label}
      placeholder={placeholder}
      onBlur={async (event) => {
        await handleBlur();
        field.onBlur(event);
      }}
      onChange={handleChange}
      error={Boolean(form.touched[field.name] && form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
      value={field.value}
      fullWidth
    />
  );
};

export default EmailField;
