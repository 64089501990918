import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// #region import LicenseManagement
// Hooks
import { useLicenseByIdWithPolling } from 'features/LicenseManagement/hooks/useLicense';
import { useDeploymentByIdPolling } from 'features/LicenseManagement/hooks/useDeployments';
// Shared Components
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import Header from 'features/LicenseManagement/components/common/Header';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import { DeploymentSteps } from 'features/LicenseManagement/components/common/DeploymentSteps';
import { PaymentMethod, steps } from 'features/LicenseManagement/components/common/Types';
// #endregion

const ResponseKeyStep = () => {
  const { t } = useTranslation('licenseManagement');
  const { deploymentId } = useParams();
  const licenseCardImageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ResponseKey.png`;
  const [licenseId, setLicenseId] = useState<string | null>(null);
  const [responseKey, setResponseKey] = useState<string>('');
  const navigate = useNavigate();

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  const {
    data: deployment,
    error: deploymentError,
    isError: isFetchDeploymentError,
    refetch: refetchDeployment
  } = useDeploymentByIdPolling(deploymentId);
  const { data: license, isError: isFetchLicenseError } = useLicenseByIdWithPolling(licenseId || '');

  useEffect(() => {
    if (deployment?.licensePublicId && !licenseId) {
      setLicenseId(deployment.licensePublicId);
    }
  }, [deployment, licenseId]);

  useEffect(() => {
    if (license?.responseKey) {
      setResponseKey(license.responseKey);
    }
  }, [license]);

  const handleCopy = () => {
    navigator.clipboard.writeText(responseKey.toString());
  };
  const handleDone = () => {
    navigate(`/licensemanagement/${deploymentId}/licenseDetails`, { replace: true });
  };

  if (!deployment) {
    return <LoadingSpinner />;
  }

  if (isFetchDeploymentError) {
    if (deploymentError.message && !deploymentError.message.includes('Timeout: License Info not received')) {
      navigate(`/licensemanagement/${deploymentId}/activateLicense/responseKeyStep`, { replace: true });
    }
  }

  return (
    <>
      <Box sx={styles.header}>
        <Header deployment={deployment} status={deployment?.licenseStatus} />
        <Box sx={styles.container}>
          <Typography variant="h6" sx={styles.title}>
            {t('Finalize_License_Deployment')}
          </Typography>
          <DeploymentSteps steps={steps(PaymentMethod.Online)} activeStep={3} />
          <Box sx={styles.textContainer}>
            <Typography variant="h6" sx={styles.responseKeyText}>
              {t('Response_Key')} : <span style={styles.responsekey}>{responseKey}</span>
              <Tooltip title={t('Copy_To_Clipboard')}>
                <IconButton onClick={handleCopy} size="small" sx={styles.copyButton}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body1" paragraph>
              {t('Activate_License_Text')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('License_Activation_Instructions')}
            </Typography>
            <Box component="img" src={licenseCardImageUrl} alt="License Card" sx={styles.licenseCardImage} />
          </Box>
          <Box sx={LicenseManagementCommonStyles.buttonContainer}>
            <Button variant="contained" onClick={handleDone} sx={LicenseManagementCommonStyles.button}>
              {t('Done')}
            </Button>
          </Box>
        </Box>
      </Box>
      {(isFetchDeploymentError || isFetchLicenseError) && (
        <ErrorAlert
          errorMessage={'Timeout: License Info not received'}
          customMessage={t('Please_Try_Again_Response_Key')}
          onRetry={refetchDeployment}
        />
      )}
      {!isFetchDeploymentError && !isFetchLicenseError && !responseKey && <LoadingSpinner />}
    </>
  );
};

const styles = {
  header: {
    pl: 5,
    pr: 3,
    pt: 2,
    maxWidth: '90%'
  },
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: 2,
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
    alignItems: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginTop: '20px',
    marginLeft: '25px'
  },
  title: {
    margin: 0,
    padding: 0
  },
  responseKeyText: {
    padding: 1,
    marginTop: 2
  },
  responsekey: {
    backgroundColor: '#f0f0f0',
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  },
  licenseCardImage: {
    flex: '0 0 auto',
    maxWidth: '300px',
    maxHeight: '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    height: 'auto',
    objectFit: 'contain'
  },
  copyButton: {
    marginLeft: '8px'
  }
};
export default ResponseKeyStep;
