import { Box, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { InvoiceDetailResponse } from '../../common/Types';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { formatDate } from '../../common/utils';

interface BillingHistoryProps {
  invoices: InvoiceDetailResponse[];
}
const BillingHistory = ({ invoices }: BillingHistoryProps) => {
  const { t } = useTranslation('licenseManagement');
  const emptyValue = '-';
  const defaultPageSize = 5;
  const [pagination, setPagination] = useState({ page: 0, pageSize: defaultPageSize });

  const renderStyledHeader = (params: GridColumnHeaderParams) => (
    <Typography variant="body2" sx={LicenseManagementCommonStyles.gridColumnHeader}>
      {params.colDef.headerName}
    </Typography>
  );

  const computeRows = (invoices: InvoiceDetailResponse[]) => {
    return invoices.map((invoice) => ({
      id: invoice.invoiceNumber ?? emptyValue,
      invoiceNumber: invoice.invoiceNumber ?? emptyValue,
      total: invoice.total ?? emptyValue,
      paidOn: invoice.paidOn ? formatDate(invoice.paidOn) : emptyValue,
      status: invoice.status ?? emptyValue,
      invoiceLink: invoice.invoiceLink
    }));
  };

  const computeColumns = (): GridColDef[] => [
    { field: 'invoiceNumber', headerName: t('Invoice_Number'), flex: 1, renderHeader: renderStyledHeader },
    { field: 'total', headerName: t('Amount'), flex: 1, renderHeader: renderStyledHeader },
    { field: 'paidOn', headerName: t('Payment_Date'), flex: 1, renderHeader: renderStyledHeader },
    { field: 'status', headerName: t('Payment_Status'), flex: 1, renderHeader: renderStyledHeader },
    {
      field: 'invoiceLink',
      headerName: t('Invoice_Detail'),
      flex: 1,
      renderHeader: renderStyledHeader,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <Link target="_blank" href={params.value} variant="body2" sx={styles.linkContainer}>
            <OpenInNewIcon color="primary" /> <span>{t('Go_to_Invoice')}</span>
          </Link>
        ) : (
          emptyValue
        )
    }
  ];

  return (
    <Box sx={styles.container}>
      <DataGrid
        rows={computeRows(invoices)}
        columns={computeColumns()}
        disableRowSelectionOnClick
        pageSizeOptions={[3, 5, 10]}
        paginationModel={pagination}
        onPaginationModelChange={(model) => setPagination(model)}
        pagination
        autoHeight={invoices.length > 0}
      />
    </Box>
  );
};

const styles = {
  container: {
    height: '100%',
    width: '100%',
    '& .MuiDataGrid-cell': {
      borderBottom: '0.0625rem solid #e0e0e0'
    },
    postion: 'relative',
    mt: 2
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  }
};

export default BillingHistory;
