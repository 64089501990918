import { useEffect, useMemo } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// #region import LicenseManagement
// Components
import PackageDetail from './PackageDetail';
// Hooks
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { useFetchStripeProducts } from 'features/LicenseManagement/hooks/useFetchStripeProducts';
// SharedComponents
import { handleContinue, handleExit } from 'features/LicenseManagement/components/common/navigationUtils';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import {
  cloudDeploymentsteps,
  DeploymentType,
  SelectedStripeProduct,
  StripeProduct
} from 'features/LicenseManagement/components/common/Types';
// #endregion

interface PackageStepProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  numDoors: number;
  setNumDoors: React.Dispatch<React.SetStateAction<number>>;
  numExpanders: number;
  setNumExpanders: React.Dispatch<React.SetStateAction<number>>;
  numCabs: number;
  setNumCabs: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStripeProducts: React.Dispatch<React.SetStateAction<SelectedStripeProduct[] | null>>;
}

const calculateDoorsPrice = (numDoors: number, stripeProducts: StripeProduct[]) => {
  const doorPriceDetails = [];
  let totalDoorCost = 0;
  let totalDiscount = 0;
  const stripeCheckoutItems = [];

  if (numDoors > 0 && stripeProducts?.length > 0) {
    // Separate products with valid maxDoorCount and the "25+ doors" product (null maxDoorCount)
    const sortedProducts = stripeProducts
      .filter((product) => product.maxDoorCount !== null)
      .sort((a, b) => a.maxDoorCount - b.maxDoorCount);

    const largestProduct = stripeProducts.find((p) => p.maxDoorCount === null);
    let remainingDoors = numDoors;

    // Process products with valid maxDoorCount
    for (const product of sortedProducts) {
      const applicableQty = Math.min(remainingDoors, product.maxDoorCount);
      if (applicableQty <= 0) continue;

      const unitPrice = product.unitAmount / 100;
      const discount = product.discount || 0;

      const rangeTotal = applicableQty * unitPrice;
      const rangeTotalDiscount = applicableQty * discount;

      doorPriceDetails.push({
        qty: applicableQty,
        price: unitPrice,
        total: rangeTotal,
        discount: discount,
        rangeTotalDiscount: rangeTotalDiscount
      });

      stripeCheckoutItems.push({
        priceId: product.priceId,
        quantity: applicableQty
      });

      totalDoorCost += rangeTotal;
      totalDiscount += rangeTotalDiscount;
      remainingDoors -= applicableQty;
    }

    // Handle remaining doors explicitly using the "25+ Door License"
    if (remainingDoors > 0 && largestProduct) {
      const unitPrice = largestProduct.unitAmount / 100;
      const discount = largestProduct.discount || 0;

      const rangeTotal = remainingDoors * unitPrice;
      const rangeTotalDiscount = remainingDoors * discount;

      doorPriceDetails.push({
        qty: remainingDoors,
        price: unitPrice,
        total: rangeTotal,
        discount: discount,
        rangeTotalDiscount: rangeTotalDiscount
      });

      stripeCheckoutItems.push({
        priceId: largestProduct.priceId,
        quantity: remainingDoors
      });

      totalDoorCost += rangeTotal;
      totalDiscount += rangeTotalDiscount;
    }
  }

  return { doorPriceDetails, totalDoorCost, totalDiscount, stripeCheckoutItems };
};

const calculateGrandTotal = (stripeProducts: StripeProduct[], numExpanders: number, numCabs: number) => {
  let expandersCost = 0;
  let cabsCost = 0;
  const stripeCheckoutItems = [];
  // Find Stripe products for Expanders and Elevator Cabs
  const expanderProduct = stripeProducts.find((p) => p.name.includes('CldIO'));
  const cabProduct = stripeProducts.find((p) => p.name.includes('CldELV'));

  // Calculate Expanders cost and add to Stripe Checkout
  if (numExpanders > 0 && expanderProduct) {
    expandersCost = (expanderProduct.unitAmount / 100) * numExpanders;
    stripeCheckoutItems.push({
      priceId: expanderProduct.priceId,
      quantity: numExpanders
    });
  }

  // Calculate Elevator Cabs cost and add to Stripe Checkout
  if (numCabs > 0 && cabProduct) {
    cabsCost = (cabProduct.unitAmount / 100) * numCabs;
    stripeCheckoutItems.push({
      priceId: cabProduct.priceId,
      quantity: numCabs
    });
  }

  const grandTotal = expandersCost + cabsCost;

  return { expandersCost, cabsCost, grandTotal, stripeCheckoutItems };
};

const PackageStep: React.FC<PackageStepProps> = ({
  activeStep,
  setActiveStep,
  numDoors,
  setNumDoors,
  numExpanders,
  setNumExpanders,
  numCabs,
  setNumCabs,
  setSelectedStripeProducts
}) => {
  const { t } = useTranslation('licenseManagement');
  const { currentUser } = useReduxUsers();
  const countryCode = currentUser?.countryId?.toString() ?? '';

  // Fetch stripe products
  const {
    data: products,
    error,
    isError: isFetchStripeProductsError,
    refetch: refetchStripeProducts
  } = useFetchStripeProducts(countryCode, DeploymentType.Cloud);

  if (isFetchStripeProductsError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={error.message}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchStripeProducts}
        />
      </Box>
    );
  }

  const memoizedResults = useMemo(() => {
    if (!products || products.length === 0)
      return { doorPriceDetails: [], totalDoorCost: 0, totalDiscount: 0, stripeCheckoutItems: [] };
    return calculateDoorsPrice(
      numDoors,
      products.filter((product) => product.productType === 'door')
    );
  }, [numDoors, products]);

  const { doorPriceDetails, totalDoorCost, totalDiscount, stripeCheckoutItems: doorItems } = memoizedResults;

  const {
    expandersCost,
    cabsCost,
    grandTotal,
    stripeCheckoutItems: expanderCabItems
  } = products && products.length > 0
    ? calculateGrandTotal(products, numExpanders, numCabs)
    : { expandersCost: 0, cabsCost: 0, grandTotal: 0, stripeCheckoutItems: [] };

  // Combine Stripe Checkout Items
  const combinedStripeCheckoutItems = useMemo(() => {
    return [...doorItems, ...expanderCabItems];
  }, [doorItems, expanderCabItems]);

  useEffect(() => {
    setSelectedStripeProducts((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(combinedStripeCheckoutItems)) {
        return prev;
      }
      return combinedStripeCheckoutItems;
    });
  }, [combinedStripeCheckoutItems, setSelectedStripeProducts]);

  const isDisabled =
    (numDoors === 0 && numExpanders === 0 && numCabs === 0) || combinedStripeCheckoutItems.length === 0;

  const InputField = ({ label, value, onChange }) => (
    <TextField
      label={label}
      type="number"
      value={value}
      inputProps={{ min: 0 }}
      onChange={(e) => onChange(Number(e.target.value))}
      sx={styles.inputField}
    />
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formSection}>
        <Typography variant="h5">{t('Select_Items')}</Typography>
        <Typography variant="subtitle2" sx={styles.planSelector}>
          <span style={styles.text}>{t('Maximum_Discount')}</span>{' '}
          <span style={styles.plan_text}>{t('Time_Period')}</span>
        </Typography>
        <Box style={styles.formContainer}>
          <Box sx={styles.formWrapper}>
            <InputField label={t('Number_of_Doors')} value={numDoors} onChange={setNumDoors} />
            <InputField label={t('Number_of_I/O_Expanders')} value={numExpanders} onChange={setNumExpanders} />
            <InputField label={t('Number_of_Elevator_Cabs')} value={numCabs} onChange={setNumCabs} />
          </Box>

          {(numDoors > 0 || numExpanders > 0 || numCabs > 0) && (
            <PackageDetail
              doorPriceDetails={doorPriceDetails}
              numCabs={numCabs}
              numExpanders={numExpanders}
              expandersCost={expandersCost}
              cabsCost={cabsCost}
              totalDiscount={totalDiscount}
              grandTotal={grandTotal + totalDoorCost}
            />
          )}
        </Box>
      </Box>

      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button variant="text" sx={LicenseManagementCommonStyles.buttonBack} onClick={handleExit}>
          {t('Exit')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={() => handleContinue(activeStep, setActiveStep, cloudDeploymentsteps.length)}
          disabled={isDisabled}
        >
          {t('Continue')}
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: 4,
    fontFamily: 'Arial, sans-serif'
  },
  formSection: {
    mb: 6
  },
  planSelector: {
    marginTop: '10px'
  },
  text: {
    color: '#006400'
  },
  plan_text: {
    color: 'textSecondary'
  },
  formWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginLeft: -2
  },
  formContainer: {
    display: 'flex',
    padding: 2,
    marginTop: 10
  },
  inputField: {
    width: '300px'
  }
};

export default PackageStep;
