import { Box, Typography } from '@mui/material';
import { usePermission } from 'context/PermissionContext';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import Header from 'features/LicenseManagement/components/common/Header';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import { LicenseStatus, DeploymentType } from 'features/LicenseManagement/components/common/Types';
import BillingHistory from 'features/LicenseManagement/components/deployment/DeploymentBilling/BillingHistory';
import FieldDisplay from 'features/LicenseManagement/components/common/Fielddisplay';
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
import { useInvoicesByDeploymentId } from 'features/LicenseManagement/hooks/useInvoices';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PaymentInformation from 'features/LicenseManagement/components/deployment/DeploymentBilling/PaymentInformation';
import { formatDate } from 'features/LicenseManagement/components/common/utils';

const DeploymentBilling = () => {
  const { t } = useTranslation('licenseManagement');
  const BILLING_PERIOD = 'Yearly';
  const { currentBranch } = useReduxBranches();
  const { deploymentId } = useParams();
  const { isAllowedTo } = usePermission();

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  const canViewDeploymentBilling = isAllowedTo(
    'deploymentBilling:view',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  const {
    data: deployment,
    isLoading: isDeploymentLoading,
    error: deploymentError
  } = useDeploymentById(deploymentId || '');

  const {
    data: invoices,
    isLoading,
    isError,
    error,
    refetch
  } = useInvoicesByDeploymentId(currentBranch?.publicId ?? '', deploymentId);

  if (!canViewDeploymentBilling) {
    return <Typography>{t('Missing_Permission_To_View_Deployment_Billing')}</Typography>;
  }

  if (isError) {
    return (
      <Box>
        <ErrorAlert errorMessage={error?.message} customMessage={t('Error_Loading_Billing_Info')} onRetry={refetch} />
      </Box>
    );
  }

  if (deploymentError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={deploymentError.message}
          customMessage={t('Error_Loading_Deployment')}
          onRetry={refetch}
        />
      </Box>
    );
  }

  if (isLoading || isDeploymentLoading) {
    return <LoadingSpinner />;
  }

  const renderField = (label: string, value: any, isLast?: boolean) => (
    <FieldDisplay label={t(label)} value={value} isLast={isLast} />
  );
  const shouldShowBillingHistory = invoices && invoices.length > 0;

  const shouldShowPrepaidMessage =
    deployment?.deploymentType === DeploymentType.Local && deployment?.licenseStatus === LicenseStatus.Activated;

  const billingHistoryContent = shouldShowBillingHistory ? (
    <BillingHistory invoices={invoices} />
  ) : shouldShowPrepaidMessage ? (
    <Box sx={styles.sectionStyle}>
      <Typography>{t('Prepaid_Payment_Message')}</Typography>
    </Box>
  ) : (
    <Box sx={styles.sectionStyle}>
      <Typography>{t('No_Prior_Billing_History')}</Typography>
    </Box>
  );

  return (
    <Box sx={LicenseManagementCommonStyles.pageHeaderContainer}>
      <Header deployment={deployment} status={deployment?.licenseStatus} />
      {deployment?.deploymentType === DeploymentType.Cloud && shouldShowBillingHistory && (
        <Box>
          <Box sx={styles.sectionContainerStyle}>
            <Typography variant="h6" gutterBottom>
              {t('Deployment_Billing')}
            </Typography>
            <Box sx={styles.sectionStyle}>
              {renderField('Billing_Plan', deployment.name)}
              {renderField('Billing_Period', BILLING_PERIOD)}
              {renderField('Next_Payment_Date', formatDate(deployment.licenseExpirationDate), true)}
            </Box>
          </Box>
          <Box sx={styles.sectionContainerStyle}>
            <Typography variant="h6" gutterBottom>
              {t('Payment_Information')}
            </Typography>
            <PaymentInformation stripeSubscriptionId={deployment.licenseStripeSubscriptionId} />
          </Box>
        </Box>
      )}
      <Box sx={styles.sectionContainerStyle}>
        <Typography variant="h6" gutterBottom>
          {t('Billing_History')}
        </Typography>
        {billingHistoryContent}
      </Box>
    </Box>
  );
};
const styles = {
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  text: {
    color: 'gray',
    fontStyle: 'italic',
    fontSize: '1.5rem',
    padding: '1rem'
  }
};
export default DeploymentBilling;
