import { useContext, useMemo } from 'react';
import { SiteContext } from 'context/SiteContext';
import { Site } from 'features/RemoteManagement/Types';
import { Branch } from 'store/slices/branchSlice';
import { CloudUser } from 'store/slices/usersSlice';

export const useUserAccessibleAiphoneSites = (currentBranch: Branch | null, currentUser: CloudUser | null) => {
  const { sitesQuery } = useContext(SiteContext);
  const sitePermissionsList = currentUser?.permissions?.site ?? {};
  const { data: myOrganizationSites = {} } = sitesQuery || {};
  const branchSites = currentBranch?.sitePublicIds;

  const filteredMySites = useMemo(() => {
    if (typeof myOrganizationSites !== 'object') return {};
    return Object.keys(myOrganizationSites).reduce((acc: Site[] | any, siteId) => {
      if (branchSites?.includes(siteId) && sitePermissionsList[siteId] !== undefined) {
        acc[siteId] = myOrganizationSites[siteId];
      }
      return acc;
    }, {});
  }, [myOrganizationSites, sitePermissionsList, currentBranch]);

  return filteredMySites;
};
