import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { Box, Typography } from '@mui/material';

// #region import LicenseManagement
// Components
import EditableSection from 'features/LicenseManagement/components/deployment/DeploymentInformation/EditableSection';
// Hooks
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
// SharedComponents
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { getFilteredStateList } from 'features/LicenseManagement/components/common/utils';
import { getCountryOptions } from 'features/LicenseManagement/components/common/GetCountryOptions';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import Header from 'features/LicenseManagement/components/common/Header';
// Types
import { DeploymentType, initializeUpdateDeploymentRequest } from 'features/LicenseManagement/components/common/Types';
// #endregion

const DeploymentInformation = () => {
  const { deploymentId } = useParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [updatedDeploymentData, setUpdatedDeploymentData] = useState<any>(null);

  const { t } = useTranslation('licenseManagement');
  const { isAllowedTo } = usePermission();
  const { currentBranch } = useReduxBranches();
  const user = useSelector((state: RootState) => state.users.currentUser);

  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const canEditDeployment = isAllowedTo('deployment:edit', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const [hasLinkedAiphoneSite, setHasLinkedAiphoneSite] = useState(false);
  const [countryId, setCountryId] = useState('');
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });

  const {
    data: deployment,
    isLoading,
    error,
    isError: isFetchDeploymentsError,
    refetch: refetchDeployments
  } = useDeploymentById(deploymentId || '');

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  // Initialize deployment data when loaded
  useEffect(() => {
    if (!isLoading && deployment && user?.publicId) {
      const initializedData = initializeUpdateDeploymentRequest(deployment, deploymentId, user.publicId);
      setUpdatedDeploymentData({
        ...initializedData,
        address: {
          ...initializedData.address,
          countryName: initializedData?.address?.countryName === 'United States' ? 'United States of America' : 'Canada'
        }
      });
      setCountryId(initializedData?.address?.countryName === 'Canada' ? '3' : '2');
      if (deployment?.linkedAiphoneSitePublicId) {
        setHasLinkedAiphoneSite(true);
      }
    }
  }, [deployment, isLoading, user, deploymentId]);

  // Fetch country list when the component mounts
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);
  // #endregion

  // #region Conditional Logic for Rendering
  if (!canViewDeployment) {
    return <Typography>{t('Missing_Permission_To_View_Deployment')}</Typography>;
  }

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  if (isFetchDeploymentsError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={error?.message}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchDeployments}
        />
      </Box>
    );
  }

  if (isLoading || !updatedDeploymentData) {
    return <LoadingSpinner />;
  }
  // #endregion

  // #region Handlers
  const handleFieldChange = (section: string, field: string, value: any) => {
    setUpdatedDeploymentData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value
      }
    }));
  };

  const renderEditableSection = (title: string, fields: any[], canEdit: boolean) => (
    <EditableSection
      formData={updatedDeploymentData}
      title={title}
      fields={fields}
      setSuccessMessage={setSuccessMessage}
      setErrorMessage={setErrorMessage}
      canUserEdit={canEdit}
    />
  );

  const handleCountryChange = (newCountry: string) => {
    const newCountryId = newCountry === 'Canada' ? '3' : '2';
    setCountryId(newCountryId);
    setUpdatedDeploymentData((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        countryName: newCountry,
        stateName: '-'
      }
    }));
  };

  const renderStateOptions = () => {
    return getFilteredStateList(countryId, enumList, (state) => ({
      value: state.value,
      label: state.value
    }));
  };

  const handleStateChange = (newState: string) => {
    setUpdatedDeploymentData((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        stateName: newState
      }
    }));
  };
  // #endregion

  // #region Render
  return (
    <Box sx={LicenseManagementCommonStyles.pageHeaderContainer}>
      <SnackbarAlert
        type={errorMessage ? 'error' : 'success'}
        time={5000}
        text={errorMessage || successMessage || ''}
        isOpen={!!errorMessage || !!successMessage}
        onClose={() => {
          setErrorMessage(null);
          setSuccessMessage(null);
        }}
      />
      <Header deployment={deployment} status={deployment?.licenseStatus} />
      {renderEditableSection(
        t('Deployment_Section_Title'),
        [
          {
            label: t('Deployment_Name'),
            value: updatedDeploymentData?.deployment.name,
            onChange: (value: any) => handleFieldChange('deployment', 'name', value)
          },
          {
            label: t('Deployment_Description'),
            value: updatedDeploymentData?.deployment.description,
            onChange: (value: any) => handleFieldChange('deployment', 'description', value)
          },
          ...(deployment?.deploymentType === DeploymentType.Local
            ? [
                {
                  label: t('Account_No'),
                  value: updatedDeploymentData?.accountNumber,
                  readonly: true
                }
              ]
            : []),
          {
            label: t('Deployment_Type'),
            value: updatedDeploymentData?.deployment.type,
            readonly: true,
            isLast: deployment?.deploymentType === DeploymentType.Local
          },
          ...(deployment?.deploymentType === DeploymentType.Cloud
            ? [
                {
                  label: t('Deployment_Subdomain_Title'),
                  value: deployment?.tenant.subdomain,
                  readonly: true,
                  isLast: true
                }
              ]
            : [])
        ],
        canEditDeployment
      )}

      {renderEditableSection(
        t('Deployment_Contact_Title'),
        [
          {
            label: t('Deployment_Contact_Name'),
            value: updatedDeploymentData?.contact.name,
            onChange: (value: any) => handleFieldChange('contact', 'name', value)
          },
          {
            label: t('Deployment_Contact_Email'),
            value: updatedDeploymentData?.contact.emailAddress,
            onChange: (value: any) => handleFieldChange('contact', 'emailAddress', value)
          },
          {
            label: t('Deployment_Contact_Phone'),
            value: updatedDeploymentData?.contact.phoneNumber,
            onChange: (value: any) => handleFieldChange('contact', 'phoneNumber', value),
            isLast: true
          }
        ],
        canEditDeployment
      )}

      {renderEditableSection(
        t('Deployment_Address_Section_Title'),
        [
          {
            label: t('Deployment_Address_Street'),
            value: updatedDeploymentData?.address.street,
            onChange: (value: any) => handleFieldChange('address', 'street', value)
          },
          {
            label: t('Deployment_Address_Apt'),
            value: updatedDeploymentData?.address.aptSuiteBuilding,
            onChange: (value: any) => handleFieldChange('address', 'aptSuiteBuilding', value)
          },
          {
            label: t('Deployment_Address_City'),
            value: updatedDeploymentData?.address.city,
            onChange: (value: any) => handleFieldChange('address', 'city', value)
          },
          {
            label: t('Deployment_Address_Postal_Code'),
            value: updatedDeploymentData?.address.postalCode,
            onChange: (value: any) => handleFieldChange('address', 'postalCode', value)
          },
          {
            label: t('Deployment_Address_Country'),
            value: updatedDeploymentData?.address.countryName,
            onChange: handleCountryChange,
            options: getCountryOptions(enumList)
          },
          {
            label: t('Deployment_Address_State'),
            value: updatedDeploymentData?.address.stateName,
            onChange: handleStateChange,
            options: renderStateOptions(),
            isLast: true
          }
        ],
        canEditDeployment && !hasLinkedAiphoneSite
      )}
    </Box>
  );
};

export default DeploymentInformation;
