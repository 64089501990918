import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
// #region import LicenseManagement
// Components
import DeploymentDataGrid from '../components/deployment/DeploymentList/DeploymentDataGrid';
import CreateDeploymentInformationModal from '../components/deployment/DeploymentList/CreateDeploymentModal/CreateDeploymentInformationModal';
import CreateDeploymentTypeModal from '../components/deployment/DeploymentList/CreateDeploymentModal/CreateDeploymentTypeModal';
// Hooks
import { useCreateDeployment } from '../hooks/useDeployments';
// SharedComponents
import LoadingSpinner from '../components/common/LoadingSpinner';
import ErrorAlert from '../components/common/ErrorAlert';
import { PermissionsContextType } from 'permissions/utils';
import { usePermission } from 'context/PermissionContext';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
// Types
import { CreateDeploymentResponse, initializeCreateDeploymentRequest } from '../components/common/Types';

// #endregion

const LicenseManagement = () => {
  const { t } = useTranslation('licenseManagement');

  // #region Navigation and Permissions
  const navigate = useNavigate();
  const { currentBranch } = useReduxBranches();
  const { isAllowedTo } = usePermission();

  const canViewDeploymentList = isAllowedTo(
    'deploymentList:view',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  const [timerExpired, setTimerExpired] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentBranch === null && canViewDeploymentList === false) {
        navigate('/');
        setTimerExpired(true);
      } else {
        navigate('/licensemanagement');
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentBranch, canViewDeploymentList, navigate]);
  // #endregion

  // #region State variables
  const [typeModalOpen, setCreateDeploymentTypeModalOpen] = useState(false);
  const [informationModalOpen, setCreateDeploymentInformationModalOpen] = useState(false);
  const [formData, setFormData] = useState(initializeCreateDeploymentRequest());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState(false);
  // #endregion

  // Mutation

  // #endregion

  // #region Mutation
  const createDeploymentMutation = useCreateDeployment(
    formData,
    (response: CreateDeploymentResponse) => {
      handleDeploymentInformationModalClose(true);
      setFormData(initializeCreateDeploymentRequest());
      navigate(`/licensemanagement/${response.deploymentId}/licenseDetails`);
    },
    (error: any) => {
      setErrorStatus(false);
      setErrorMessage(error.message);
      setTimeout(() => setErrorStatus(true), 0);
    }
  );
  // #endregion

  // #region Handlers
  const handleDeploymentTypeModalOpen = () => {
    setCreateDeploymentTypeModalOpen(true);
  };

  const handleDeploymentTypeModalClose = (resetForm: boolean) => {
    if (resetForm) {
      setFormData(initializeCreateDeploymentRequest());
    }
    setCreateDeploymentTypeModalOpen(false);
  };

  const handleDeploymentInformationModalOpen = () => {
    setCreateDeploymentInformationModalOpen(true);
  };

  const handleDeploymentInformationModalClose = (resetForm: boolean) => {
    if (resetForm) {
      setFormData(initializeCreateDeploymentRequest());
    }
    setCreateDeploymentInformationModalOpen(false);
  };

  const handleCreateDeployment = () => {
    createDeploymentMutation.mutate();
  };
  // #endregion

  // #region Render
  if (!timerExpired && currentBranch === null && canViewDeploymentList === false) {
    return <LoadingSpinner />;
  }

  if (!canViewDeploymentList) {
    navigate('/');
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.titleContainer}>
          <Typography variant="h5" sx={styles.heading}>
            {t('License_Management')}
          </Typography>
          <Typography variant="body2" sx={styles.subheading}>
            {t('Dashboard_Title_Description')}
          </Typography>
        </Box>
      </Box>

      <CreateDeploymentTypeModal
        open={typeModalOpen}
        onOpenInformationModal={handleDeploymentInformationModalOpen}
        onClose={handleDeploymentTypeModalClose}
        formData={formData}
        setFormData={setFormData}
      />

      <CreateDeploymentInformationModal
        open={informationModalOpen}
        onOpenTypeModal={handleDeploymentTypeModalOpen}
        onClose={handleDeploymentInformationModalClose}
        formData={formData}
        setFormData={setFormData}
        onCreateLocalDeployment={handleCreateDeployment}
        isPending={createDeploymentMutation.isPending}
      />

      {canViewDeploymentList && <DeploymentDataGrid addNewDeployment={handleDeploymentTypeModalOpen} />}

      {errorStatus && <ErrorAlert errorMessage={errorMessage} customMessage={t('Deployment_Creation_Error')} />}
    </Box>
  );
  // #endregion
};

const styles = {
  container: {
    padding: '1.25rem',
    fontWeight: 400,
    width: '75rem',
    margin: '0 auto'
  },
  header: {
    marginBottom: '0.625rem'
  },
  titleContainer: {
    marginBottom: '1.875rem'
  },
  heading: {
    fontWeight: 500
  },
  subheading: {
    color: 'text.secondary'
  }
};

export default LicenseManagement;
