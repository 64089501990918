import { GridRowProps } from '@mui/x-data-grid';

export const MarketType = [
  { value: 'Commercial/Office' },
  { value: 'Commercial/Retail' },
  { value: 'Correctional' },
  { value: 'Education/School' },
  { value: 'Government Federal' },
  { value: 'Government State' },
  { value: 'Healthcare' },
  { value: 'Industrial' },
  { value: 'Recruiting Center' },
  { value: 'Religious' },
  { value: 'Residential' },
  { value: 'Other' }
];

export interface Unit {
  UnitType: string;
  UnitNumber: string;
  UnitName: string;
  BuildingNumber: number;
  NoOfApps: number | null;
  Tenants: string[];
  Devices: string[];
  Apps: string[];
}

export interface Tenant {
  id: string;
  FirstName: string;
  LastName: string;
  UnitNumber: string;
  AccessCode: string;
}

export interface Tenants {
  tenants: GridRowProps[];
}

export interface Site {
  publicId: string;
  siteName: string;
  aclSiteId?: string;
  siteAddress?: string;
  quikSpecId?: string;
  organizationId?: string;
  createdOn?: string;
  lastUpdatedOn: string;
  registeredGatewayPublicId?: string;
}

export enum DeviceCategory {
  MasterStation = 'Master Station',
  SubMasterStation = 'Sub Master Station',
  EntranceStation = 'Entrance Station',
  TenantStation = 'Tenant Station',
  GuardStation = 'Guard Station',
  VideoDoorStation = 'Video Door Station',
  AudioDoorStation = 'Audio Door Station',
  EmergencyStation = 'Emergency Station',
  IOAdaptor = 'IO Adaptor',
  GatewayAdaptor = 'Gateway Adaptor',
  LiftControl = 'Lift Control'
}

export enum DeviceGroups {
  MasterStation = 'MasterStation',
  SubMasterStation = 'SubMasterStation',
  EntranceStation = 'EntranceStation',
  TenantStation = 'TenantStation',
  GuardStation = 'GuardStation',
  VideoDoorStation = 'VideoDoorStation',
  AudioDoorStation = 'AudioDoorStation',
  EmergencyStation = 'EmergencyStation',
  IOAdaptor = 'IOAdaptor',
  GatewayAdaptor = 'GatewayAdaptor',
  LiftControl = 'LiftControl'
}

export enum UserRoles {
  Admin = 'Admin',
  PropertyManager = 'Property Manager',
  Installer = 'Installer',
  Billing = 'Billing'
}
export interface DeviceType {
  deviceCategory: DeviceCategory;
  deviceList: string[];
  stationNumberStartingIndex: number;
  deviceGroup: string;
  assignedIndex: number | null;
  allowedUnitType: number[];
}

export interface RDSDevice {
  device_type: number;
  device_model: number;
  quantity: number;
}

export interface BasicInfoDevice {
  basicInfo: {
    adminId: string;
    adminPass: string;
    dateTime: string;
    daylightSavings: boolean;
    devicePublicId: string;
    deviceType: number;
    macAddress: string;
    rtspId: string;
    rtspPass: string;
    stationLanguage: number;
    stationLanguageVer2: number;
    stationLocation: string;
    stationName: string;
    stationNumber: string;
    stationPronunciationName: string;
    timezone: number;
    timezoneVer2: number;
    userId: string;
    userPass: string;
  };
  publicId: string;
}

export interface SearchDevice {
  id: string;
  mac_addr: string;
  ip_ver: string;
  ip_addr: string;
  ip_subnet: string;
  ip_gateway: string;
  conn_port: string;
  device_type: string;
  model_number: string;
  station_name: string;
  station_number: string;
  station_type: string;
  location: string;
  fw_ver: string;
  sub_cpu_ver: string;
}

/** Door stations are the device type that match the following number
 * device type can be find deviceList[devicePublicId].basicInfo.deviceType: number
 **/

export const DOOR_STATIONS = [8, 9, 10, 11, 20];
export const MASTER_STATIONS = [4, 5, 14, 16];
export const MAX_APPS = 8;

export const DeviceModelMap: { [key: number]: { [key: number]: string } } = {
  4: {
    1: 'IX-MV7-HB-L',
    2: 'IX-MV7-B',
    3: 'IX-MV7-W',
    4: 'IX-MV7-HB',
    5: 'IX-MV7-HW-JP'
  },
  5: {
    1: 'IX-RS-B',
    2: 'IX-RS-W'
  },
  6: {
    1: 'IXW-MA',
    2: 'IXW-MAA'
  },
  8: {
    1: 'IX-DV',
    2: 'IX-DVF',
    3: 'IX-DVF-4A',
    4: 'IX-DVF-6',
    5: 'IX-DVF-HW',
    6: 'IX-DVF-L',
    7: 'IX-DVF-PR',
    8: 'IX-DVF-RA',
    9: 'IX-DVF-2RA',
    10: 'IX-DVF-10KP'
  },
  9: {
    1: 'IX-SSA',
    2: 'IX-SSA-RA',
    3: 'IX-SSA-2RA'
  },
  10: {
    1: 'IX-SS-2G',
    2: 'IX-NVP'
  },
  11: {
    1: 'IX-EA'
  },
  12: {
    1: 'IX-FA'
  },
  14: {
    1: 'IXG-2C7',
    2: 'IXG-2C7-L'
  },
  15: {
    1: 'IXG-DM7-HID'
  },
  16: {
    1: 'IXG-MK'
  },
  17: {
    1: 'IXGW-LC',
    2: 'IXGW-LC-RY20'
  },
  18: {
    1: 'IXGW-GW',
    2: 'IXGW-TGW'
  },
  20: {
    1: 'IX-DVM'
  }
};

export const DeviceTypes: { [key: number]: string } = {
  4: 'Master Station',
  5: 'Sub Master Station',
  6: 'IO Adaptor',
  8: 'Video Door Station',
  9: 'Audio Door Station',
  10: 'Audio Door Station',
  11: 'Video Door Station',
  14: 'Tenant Station',
  15: 'Entrance Station',
  16: 'Guard Station',
  17: 'Lift Control Adaptor',
  18: 'Gateway Adaptor',
  20: 'Video Door Station'
};

export const DeviceTabs = [
  {
    index: 0,
    label: 'Master Station',
    deviceType: [4]
  },
  {
    index: 1,
    label: 'Tenant Station',
    deviceType: [14]
  },
  {
    index: 2,
    label: 'Sub Master Station',
    deviceType: [5]
  },
  {
    index: 3,
    label: 'Guard Station',
    deviceType: [16]
  },
  {
    index: 4,
    label: 'Entrance Station',
    deviceType: [15]
  },
  {
    index: 5,
    label: 'Video Door Station',
    deviceType: [8, 11, 20]
  },
  {
    index: 6,
    label: 'Audio Door Station',
    deviceType: [9, 10]
  },
  {
    index: 7,
    label: 'IO Adaptor',
    deviceType: [6]
  },
  {
    index: 8,
    label: 'Gateway Adaptor',
    deviceType: [18]
  },
  {
    index: 9,
    label: 'Lift Control',
    deviceType: [17]
  }
];

export enum SearchDeviceTypes {
  IXMV7 = 'IX-MV7',
  IXRS = 'IX-RS',
  IXDV = 'IX-DV',
  IXDVM = 'IX-DVM',
  IXSSA = 'IX-SSA',
  IXEA = 'IX-EA',
  IXFA = 'IX-FA',
  IXSS2G = 'IX-SS-2G',
  IXWMA = 'IXW-MA',
  IXGDM7 = 'IXG-DM7',
  IXGMK = 'IXG-MK',
  IXG2C7 = 'IXG-2C7',
  IXGWLC = 'IXGW-LC',
  IXGWGW = 'IXGW-GW'
}

export const MasterStations = [
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-SOFT',
  'IX-RS',
  'IX-RS-*',
  'IX-RS-B',
  'IX-RS-W',
  'IXG-MK'
];

export const DoorStations = [
  'IX-DV*',
  'IX-DV',
  'IX-DVM',
  'IX-DVF',
  'IX-DVF-PR',
  'IX-DVF-4A',
  'IX-DVF-6',
  'IX-DVF-L',
  'IX-DVF-HW',
  'IX-EA',
  'IX-SS-2G',
  'IX-SSA',
  'IX-NVP',
  'IX-NVP2',
  'IX-FA',
  'IX-DVF-10KP',
  'IX-NVP'
];

export const UnitTypeNames = {
  1: {
    name: 'Guard',
    description:
      'Guard Units consist of up to eight IXG-MK Guard Stations or IX-RS-* Handset Sub Stations, up to eight IXG Apps, or a combination of both. The two private Door Stations will only call to stations within their Unit.'
  },
  2: { name: 'Entrance', description: 'Entrance Units consist of IXG-DM7-* Entrance Stations.' },
  3: {
    name: 'InsideArea',
    description:
      'Inside Area Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* Handset Sub Stations, and two IX Series Door Stations.'
  },
  4: {
    name: 'Residential',
    description:
      'Residential Units may consist of up to eight IXG-2C7 tenant stations or IX-RS-* Handset Sub Stations, eight IXG Apps, or a combination of both.'
  },
  5: {
    name: 'Commercial',
    description:
      'Commercial Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* Handset Sub Stations, eight IXG Apps, or a combination of both.'
  },
  6: {
    name: 'OutsideArea',
    description:
      'Outside Area Units consist of up to 10 Door or Emergency Stations that can call Residential, Guard, Commercial, and Inside Area units.'
  }
};

export enum UnitTypesEnum {
  Guard = 1,
  Entrance = 2,
  InsideArea = 3,
  Residential = 4,
  Commercial = 5,
  OutsideArea = 6
}

export const EmergencyStations = ['IX-DVF-RA', 'IX-DVF-2RA', 'IX-SSA-RA', 'IX-SSA-2RA'];

export const IOAdapter = ['IXW-MA', 'IXW-MAA'];

export const GatewayAdaptor = ['IXGW-GW', 'IXGW-TGW'];

// TODO: Replace this dummy value with a real Lift Control
export const LiftControls = ['IXGW-LC-RY20', 'IXGW-LC'];

export const EntranceStations = ['IXG-DM7-HID', 'IXG-DM7', 'IXG-DM7-*'];

export const TenantStations = ['IXG-2C7', 'IXG-2C7-L'];

export const GuardStations = ['IXG-MK'];

export const UnitAllowedDevices: { [key: string]: number[] } = {
  Entrance: [15],
  Residential: [14, 5, 8, 9, 10, 11],
  Guard: [16, 5, 8, 9, 10, 11],
  Commercial: [4, 5, 8, 9, 10, 11],
  InsideArea: [16, 4, 5, 8, 9, 10, 11],
  OutsideArea: [8, 9, 10, 11]
};

export const DeviceCategories: DeviceType[] = [
  {
    deviceCategory: DeviceCategory.MasterStation,
    deviceList: MasterStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.MasterStation,
    assignedIndex: null,
    allowedUnitType: [3, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.SubMasterStation,
    deviceList: MasterStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.SubMasterStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5]
  },
  {
    deviceCategory: DeviceCategory.EntranceStation,
    deviceList: EntranceStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.EntranceStation,
    assignedIndex: null,
    allowedUnitType: [2]
  },
  {
    deviceCategory: DeviceCategory.TenantStation,
    deviceList: TenantStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.TenantStation,
    assignedIndex: null,
    allowedUnitType: [4]
  },
  {
    deviceCategory: DeviceCategory.GuardStation,
    deviceList: GuardStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.GuardStation,
    assignedIndex: null,
    allowedUnitType: [1, 3]
  },
  {
    deviceCategory: DeviceCategory.VideoDoorStation,
    deviceList: DoorStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.VideoDoorStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.AudioDoorStation,
    deviceList: DoorStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.AudioDoorStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.EmergencyStation,
    deviceList: EmergencyStations,
    stationNumberStartingIndex: 4,
    deviceGroup: DeviceGroups.EmergencyStation,
    assignedIndex: null,
    allowedUnitType: [6]
  },
  {
    deviceCategory: DeviceCategory.GatewayAdaptor,
    deviceList: GatewayAdaptor,
    stationNumberStartingIndex: 3,
    deviceGroup: DeviceGroups.GatewayAdaptor,
    assignedIndex: null,
    allowedUnitType: []
  },
  {
    deviceCategory: DeviceCategory.LiftControl,
    deviceList: LiftControls,
    stationNumberStartingIndex: 5,
    deviceGroup: DeviceGroups.LiftControl,
    assignedIndex: null,
    allowedUnitType: []
  },
  {
    deviceCategory: DeviceCategory.IOAdaptor,
    deviceList: IOAdapter,
    stationNumberStartingIndex: 3,
    deviceGroup: DeviceGroups.IOAdaptor,
    assignedIndex: null,
    allowedUnitType: []
  }
];

export const EntranceDevices = ['IXG-DM7-HID', 'IXG-DM7', 'IXG-DM7-*'];
export const DoorDevices = [
  'IX-DV',
  'IX-DVF',
  'IX-DVF-PR',
  'IX-SSA-RA',
  'IX-SSA-2RA',
  'IX-SS-2G',
  'IX-EA',
  'IX-FA',
  'IX-DVF-10KP',
  'IX-NVP'
];
export const ResidentialDevices = [...DoorDevices, 'IXG-2C7', 'IXG-2C7-L', 'IX-RS-B', 'IX-RS-W'];

export const GuardDevices = ['IXG-MK', 'IX-RS-B', 'IX-RS-W', ...DoorDevices];
export const CommercialDevices = [
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-RS-B',
  'IX-RS-W',
  ...DoorDevices
];

export const InsideAreaDevices = [
  'IXG-MK',
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-RS-B',
  'IX-RS-W',
  'IX-RS-*',
  ...DoorDevices
];
export const OutsideAreaDevices = [...DoorDevices];

export const UnitTypes: any = [
  {
    unitType: 'Entrance',
    unitTypeNumber: 2,
    deviceList: EntranceDevices,
    unitTypeDescription: `Entrance Units consist of IXG-DM7-* Entrance Stations. Entrance Stations can call Guard, 
  Commercial, and Tenant Units and their stations, as well as grant entry to residents by keypad 
  or card reader.`
  },
  {
    unitType: 'Residential',
    unitTypeNumber: 4,
    deviceList: ResidentialDevices,
    unitTypeDescription: `Residential Units may consist of up to eight IXG-2C7 tenant stations or 
  IX-RS-* Handset Sub Stations, eight IXG Apps, or a combination of both. Tenant 
  Stations can communicate internally within the Unit, receive incoming calls from 
  Entrance and Guard Units, and monitor Entrance Stations. The two private door 
  stations will only call to stations within their Unit`
  },
  {
    unitType: 'Guard',
    unitTypeNumber: 1,
    deviceList: GuardDevices,
    unitTypeDescription: `Guard Units consist of up to eight IXG-MK Guard Stations or IX-RS-* Handset 
  Sub Stations, up to eight IXG Apps, or a combination of both. The two private 
  Door Stations will only call to stations within their Unit.`
  },
  {
    unitType: 'Commercial',
    unitTypeNumber: 5,
    deviceList: CommercialDevices,
    unitTypeDescription: `Commercial Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* 
  Handset Sub Stations, eight IXG Apps, or a combination of both. These master 
  stations can utilize many IX Series functions including internal paging, call 
  transfer, speed dial buttons, and more. 
  Stations within a Commercial Unit can communicate with Inside and Outside 
  Area units, Guard and Entry Units, as well as other Commercial Units. The two 
  private Door Stations will only call to stations within their Unit.`
  },
  {
    unitType: 'Inside Area',
    unitTypeNumber: 3,
    deviceList: InsideAreaDevices,
    unitTypeDescription: `Inside Area Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* 
  Handset Sub Stations, and two IX Series Door Stations. These stations can 
  utilize many IX Series functions including internal paging, call transfer, speed 
  dial buttons, and more. 
  An Inside Area Master Station can communicate with all other Unit types, 
  except for the Guard Unit. The two private Door Stations will only call to 
  stations within their Unit.`
  },
  {
    unitType: 'Outside Area',
    unitTypeNumber: 6,
    deviceList: OutsideAreaDevices,
    unitTypeDescription: `Outside Area Units consist of up to 10 Door or Emergency Stations that can call 
  Residential, Guard, Commercial, and Inside Area units. `
  }
];

export type Plan = {
  title: string;
  cost: string;
  featured: boolean;
  featuresList: Feature[];
};

export type Feature = {
  title: string;
  included: boolean;
};

export type Service = {
  title: string;
  active: boolean;
  onClick?: () => void;
};

export interface IRingtone {
  id: number;
  public_id: string;
  site_public_id: string | null;
  sound_name: string;
  sound_file_name: string;
  s3_location: string;
  last_updated_by: string;
  last_updated_on: string;
  created_by: string;
  created_on: string;
}

//**** REGEX TEMPLATES ****\\

// IP Address - Validated here: https://regex101.com/r/GGIPUi/3
export const RegexIpV4String =
  '(?:(?:[1-9]|[1-9]\\d|1\\d\\d|2[0-1]\\d|22[0-3])\\.(?:(?:25[0-5]|2[0-4]\\d|[01]?\\d\\d?)\\.){2}(?:[1-9]|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-4]))';

// TODO: This needs tested for all cases.
export const RegexIpV6String =
  '(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|' + // TEST: 1:2:3:4:5:6:7:8
  '([0-9a-fA-F]{1,4}:){1,7}:|' + // TEST: 1::                              1:2:3:4:5:6:7::
  '([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|' + // TEST: 1::8             1:2:3:4:5:6::8  1:2:3:4:5:6::8
  '([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|' + // TEST: 1::7:8           1:2:3:4:5::7:8  1:2:3:4:5::8
  '([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|' + // TEST: 1::6:7:8         1:2:3:4::6:7:8  1:2:3:4::8
  '([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|' + // TEST: 1::5:6:7:8       1:2:3::5:6:7:8  1:2:3::8
  '([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|' + // TEST: 1::4:5:6:7:8     1:2::4:5:6:7:8  1:2::8
  '[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|' + // TEST: 1::3:4:5:6:7:8   1::3:4:5:6:7:8  1::8
  ':((:[0-9a-fA-F]{1,4}){1,7}|:)|' + // TEST: ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8 ::8       ::
  'fe08:(:[0-9a-fA-F]{1,4}){2,2}%[0-9a-zA-Z]{1,}|' + // TEST: fe08::7:8%eth0      fe08::7:8%1                                      (link-local IPv6 addresses with zone index)
  `::(ffff(:0{1,4}){0,1}:){0,1}"${RegexIpV4String}|` + // TEST: ::255.255.255.255   ::ffff:255.255.255.255  ::ffff:0:255.255.255.255 (IPv4-mapped IPv6 addresses and IPv4-translated addresses)
  `([0-9a-fA-F]{1,4}:){1,4}:${RegexIpV4String})`; // TEST: 2001:db8:3:4::192.0.2.33  64:ff9b::192.0.2.33                        (IPv4-Embedded IPv6 Address)

const RegexDomainNameString =
  '(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])';

const RegexIpV4OrDomainString = `(${RegexIpV4String}|${RegexDomainNameString})`;
const RegexIpV6OrDomainString = `(${RegexIpV6String}|${RegexDomainNameString})`;

// Subnet Mask - Validated here: https://regex101.com/r/Pv1kZq/2
const RegexSubnetMaskString =
  '(((255\\.){3}(255|254|252|248|240|224|192|128|0+))|' +
  '((255\\.){2}(255|254|252|248|240|224|192|128|0+)\\.0)|' +
  '((255\\.)(255|254|252|248|240|224|192|128|0+)(\\.0+){2})|' +
  '((255|254|252|248|240|224|192|128|0+)(\\.0+){3}))';

export const RegexIpV4 = new RegExp(`^${RegexIpV4String}$`);
export const RegexIpV6 = new RegExp(`^${RegexIpV6String}$`);
export const RegexDomainName = new RegExp(`^${RegexDomainNameString}$`);
export const RegexIpV4OrDomain = new RegExp(`^${RegexIpV4OrDomainString}$`);
export const RegexIpV6OrDomain = new RegExp(`^${RegexIpV6OrDomainString}$`);
export const RegexSubnetMask = new RegExp(`^${RegexSubnetMaskString}$`);
