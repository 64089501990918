import React from 'react';
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';

interface ActionContainerProps {
  canCreateDeployment: boolean;
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeploymentTypeModalOpen: () => void;
}

const ActionContainer = ({
  canCreateDeployment,
  searchQuery,
  handleSearchChange,
  handleDeploymentTypeModalOpen
}: ActionContainerProps) => {
  const { t } = useTranslation('licenseManagement');

  return (
    <Box sx={styles.actionContainer}>
      <TextField
        variant="outlined"
        placeholder={t('Search_Placeholder')}
        sx={styles.searchField}
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      {canCreateDeployment && (
        <Button
          variant="contained"
          color="primary"
          sx={LicenseManagementCommonStyles.button}
          onClick={handleDeploymentTypeModalOpen}
        >
          + {t('Deploy_New_License')}
        </Button>
      )}
    </Box>
  );
};

const styles = {
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
    height: '2.5rem'
  },
  searchField: {
    width: '25.625rem',
    '& .MuiInputBase-root': {
      height: '2.8rem',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    }
  }
};

export default ActionContainer;
