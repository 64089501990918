export const productDescriptions: Record<string, { description: string }> = {
  'AC-NIO-LB1': {
    description: '1000 Doors, 1000 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-LB5': {
    description: '1000 Doors, 1000 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-EP1': {
    description: 'UNLIMITED Doors, UNLIMITED Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-EP5': {
    description: 'UNLIMITED Doors, UNLIMITED Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-ST1': {
    description: '250 Doors, 250 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-ST5': {
    description: '250 Doors, 250 Cameras, UNLIMITED Partitions. Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-MB1': {
    description: '500 Doors, 500 Cameras, UNLIMITED Partitions. Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-MB5': {
    description: '500 Doors, 500 Cameras, UNLIMITED Partitions. Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-BS1': {
    description: '40 Doors, 40 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-BS5': {
    description: '40 Doors, 40 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-ESS1': {
    description: '80 Doors, 80 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-ESS5': {
    description: '80 Doors, 80 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-SB1': {
    description: '125 Doors, 125 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  },
  'AC-NIO-SB5': {
    description: '125 Doors, 125 Cameras, UNLIMITED Partitions.Elavatrors, LDAP and Unmanaged Doors'
  }
};
