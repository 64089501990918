/**
 * @constant LicenseManagementCommonStyles
 * @description This is a style object for the common styles for the License Management page.
 */
export const LicenseManagementCommonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1rem'
  },
  button: {
    height: '2.5rem',
    fontWeight: 600,
    textTransform: 'none'
  },
  buttonBack: {
    height: '2.5rem',
    fontWeight: 600,
    textTransform: 'none',
    color: '#0071CE'
  },
  loadingSpinnerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1300,
    cursor: 'wait'
  },
  pageHeaderContainer: {
    pl: 5,
    pr: 3,
    pt: 2,
    width: '80rem'
  },
  gridColumnHeader: {
    fontWeight: 'bold',
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  countryMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  countryMenuItemFlagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  }
};
