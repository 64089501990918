import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { fetchPaymentInfo } from '../lib/api/LicenseManagementApi';
import { PaymentInfo } from '../components/common/Types';

const STALE_TIME = 60000;

export const useFetchPaymentInfo = (subscriptionId: string): UseQueryResult<PaymentInfo> => {
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useQuery<PaymentInfo>({
    queryKey: ['paymentInfo', subscriptionId],
    queryFn: () => fetchPaymentInfo(branchId ?? '', subscriptionId),
    enabled: !!subscriptionId,
    staleTime: STALE_TIME
  });
};
