import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n
import InviteAiphoneUserDialog from './Dialogs/InviteAiphoneUserDialog';
import EditAiphoneUserDialog from './Dialogs/EditAiphoneUserDialog';
import ResetPasswordDialog from './Dialogs/ResetPasswordDialog';
import { Add } from '@mui/icons-material';
import { CloudUser } from 'store/slices/usersSlice';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { CloudRole, UserType } from 'shared/utils/UserUtils';
import useGetUsers from 'hooks/useGetUsers';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteAccountDialog from 'shared/components/dialogs/DeleteAccountDialog';

interface IGetAiphoneUsersResponse {
  userList: { [key: string]: CloudUser };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalUsers: number;
}

const AiphoneUsersDataGrid = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [isInviteUserDialogOpen, setisInviteUserDialogOpen] = React.useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = React.useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<CloudUser>();
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [usersList, setUsersList] = React.useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [firstPageLoaded, setFirstPageLoaded] = React.useState(false);
  const [rows, setRows] = React.useState<GridRowsProp>([]);

  // Check the logged in user's role
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser
    ? currentUser.permissions.global?.roleList.find((role) => role.roleName === CloudRole.AIPHONE_ADMIN)
    : false;

  /* Fetch users */
  const { data, error, refetch } = useGetUsers(currentPage, pageSize, UserType.GLOBAL_ROLE);
  const usersData: IGetAiphoneUsersResponse = data as IGetAiphoneUsersResponse;

  /* Hooks to handle users' data */
  useEffect(() => {
    if (usersData?.userList) {
      setUsersList({ ...usersList, ...usersData.userList });
      if (currentPage === 0) {
        setFirstPageLoaded(true);
      }
    }
    if (usersData) {
      const nextPageAvailable: boolean = usersData.pageNumber < usersData.totalPages;
      if (nextPageAvailable) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [usersData]);

  useEffect(() => {
    setRows(computeRows(usersList));
  }, [usersList]);

  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage(t('Error_Unauthorized_Fetch_Users'));
      } else {
        setErrorMessage(t('Error_Fetch_Users'));
      }
      setIsError(true);
    }
  }, [error]);

  /* Prepare rows and columns for DataGrid */
  const computeRows = (users: { [key: string]: CloudUser }): GridRowsProp => {
    if (Object.keys(users).length === 0) {
      return [];
    }
    return Object.values(users).map((value: CloudUser) => {
      return {
        id: value.publicId,
        firstName: value.firstName,
        lastName: value.lastName,
        role: value.permissions.global?.roleList[0].roleName,
        email: value.email
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [
      { field: 'firstName', type: 'string', headerName: t('First_Name'), flex: 1 },
      { field: 'lastName', type: 'string', headerName: t('Last_Name'), flex: 1 },
      {
        field: 'role',
        type: 'string',
        headerName: t('Role'),
        flex: 1,
        valueGetter: (param) => {
          return param.value === CloudRole.AIPHONE_ADMIN ? t('Admin') : t('Member');
          s;
        }
      },
      { field: 'email', type: 'string', headerName: t('Email'), flex: 1 }
    ];

    if (hasGlobalAdminRole) {
      columns.push({
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => {
          // Check if the current user matches the user in the row
          const isCurrentUser = currentUser?.publicId === params.row.id;
          // If the user is the current user, do not show the actions (edit, reset password)
          if (isCurrentUser) return [];

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label={t('Edit_User')}
              showInMenu
              onClick={() => {
                setIsEditUserDialogOpen(true);
                setSelectedUser(usersList[params.row.id]);
              }}
            />,
            <GridActionsCellItem
              icon={<LockResetIcon />}
              label={t('Reset_Password')}
              showInMenu
              onClick={() => {
                setIsResetPasswordDialogOpen(true);
                setSelectedUser(usersList[params.row.id]);
              }}
            />
          ];
        }
      });
    }

    return columns;
  };

  const columns: GridColDef[] = computeColumns();

  const AiphoneUsersToolbar = () => {
    return (
      <GridToolbarContainer>
        {hasGlobalAdminRole && (
          <Box sx={styles.w100}>
            <Button onClick={() => setisInviteUserDialogOpen(true)} disabled={!firstPageLoaded} startIcon={<Add />}>
              {t('Invite_User')}
            </Button>
            <Button
              onClick={() => {
                setIsDeleteUserDialogOpen(true);
              }}
              disabled={!firstPageLoaded || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              {t('Delete_User')}
            </Button>
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={!firstPageLoaded}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: AiphoneUsersToolbar, moreActionsIcon: SettingsIcon }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          pagination
          autoHeight
        />
      </Box>
      <InviteAiphoneUserDialog
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setisInviteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <DeleteAccountDialog
        isOpen={isDeleteUserDialogOpen}
        onClose={() => setIsDeleteUserDialogOpen(false)}
        selectionModel={selectionModel}
      />
      <EditAiphoneUserDialog
        isOpen={isEditUserDialogOpen}
        setIsOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <ResetPasswordDialog
        isOpen={isResetPasswordDialogOpen}
        setIsOpen={setIsResetPasswordDialogOpen}
        selectedUser={selectedUser?.publicId}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        resetUserType="Aiphone"
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default AiphoneUsersDataGrid;
