import { EnumList, ICountryValue } from 'shared/utils/EnumUtils';
import { LicenseManagementCommonStyles as CommonStyles } from '../../styles/CommonStyles';
import { CA, US } from 'country-flag-icons/react/3x2';
import { Grid } from '@mui/material';

export const getCountryOptions = (enumList: EnumList) => {
  const validCountries = ['CA', 'US']; // Valid country codes (Canada and USA)

  return Object.keys(enumList.country)
    .map((key) => {
      const country = enumList.country[key] as ICountryValue;

      if (validCountries.includes(country.alpha2Code)) {
        const flagComponent: JSX.Element =
          country.alpha2Code === 'CA' ? (
            <CA title={country.defaultLanguageName} style={CommonStyles.countryMenuItemFlagStyle} />
          ) : (
            <US title={country.defaultLanguageName} style={CommonStyles.countryMenuItemFlagStyle} />
          );

        return {
          value: country.value,
          label: (
            <Grid sx={CommonStyles.countryMenuItem}>
              {flagComponent}
              {country.value}
            </Grid>
          )
        };
      }
      return null;
    })
    .filter((option) => option !== null);
};
