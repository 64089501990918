import { Box, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateDeploymentByDeploymentId } from '../../../hooks/useDeployments';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FieldDisplay from '../../common/Fielddisplay';

interface EditableSectionProps {
  formData: any;
  title: string;
  fields: any;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
  canUserEdit: boolean;
}

const EditableSection = ({
  formData,
  title,
  fields,
  setSuccessMessage,
  setErrorMessage,
  canUserEdit
}: EditableSectionProps) => {
  const { t } = useTranslation('licenseManagement');
  const [isEditing, setIsEditing] = useState(false);
  const [originalFields, setOriginalFields] = useState(fields);
  const [currentFields, setCurrentFields] = useState(fields);

  useEffect(() => {
    setCurrentFields(fields);
  }, [fields]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const updateDeploymentByDeploymentIdMutation = useUpdateDeploymentByDeploymentId(formData.deploymentId, formData, {
    onSuccess: () => {
      setOriginalFields(fields);
      setCurrentFields(fields);
      setSuccessMessage(t('Deployment_Update_Success'));
    },
    onError: () => {
      setCurrentFields(originalFields);
      setErrorMessage(t('Deployment_Update_Failed'));
    }
  });

  const handleSaveClick = () => {
    const updatedFormData = { ...formData };

    // Check if country is 'United States of America' and update it to 'United States' as we get error while updating country with name as 'United States of America'
    if (updatedFormData.address.countryName === 'United States of America') {
      updatedFormData.address.countryName = 'United States';
    }
    setIsEditing(false);
    updateDeploymentByDeploymentIdMutation.mutate(updatedFormData);
  };

  const handleDiscardClick = () => {
    setCurrentFields(originalFields);
    setIsEditing(false);
  };
  return (
    <Box sx={styles.sectionContainerStyle}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {canUserEdit && isEditing ? (
        <Box sx={styles.editBox}>
          <Button onClick={handleDiscardClick} sx={styles.discardButtonStyle}>
            <ClearIcon sx={styles.clearIconStyle}></ClearIcon>
            {t('Discard')}
          </Button>

          <Button onClick={handleSaveClick} sx={styles.saveButtonStyle}>
            <CheckIcon sx={styles.checkIconStyle}></CheckIcon>
            {t('Save')}
          </Button>
        </Box>
      ) : canUserEdit ? (
        <Button startIcon={<EditIcon />} onClick={handleEditClick} sx={styles.editButtonStyle} aria-label="edit">
          {t('Edit')}
        </Button>
      ) : null}

      <Box sx={styles.sectionStyle}>
        {currentFields.map((field, index) => (
          <FieldDisplay key={index} {...field} isEditing={isEditing} />
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  editButtonStyle: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#0071CE',
    textTransform: 'uppercase'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2
  },
  fieldLabel: {
    marginBottom: 1
  },
  fieldValue: {
    marginBottom: 1
  },
  saveButtonStyle: {
    color: '#0071CE',
    textTransform: 'uppercase'
  },
  discardButtonStyle: {
    color: 'grey',
    textTransform: 'uppercase'
  },
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 1,
    alignItems: 'center'
  },
  checkIconStyle: {
    color: '#0071CE',
    marginRight: 1
  },
  clearIconStyle: {
    color: 'grey',
    marginRight: 1
  }
};

export default EditableSection;
