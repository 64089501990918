import { useRef } from 'react';
import { useQuery, useMutation, UseMutationOptions } from '@tanstack/react-query';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { createActivatedLicenseWithCard, fetchLicenseById, validateLicenseCard } from '../lib/api/LicenseManagementApi';
import {
  CreateActivatedLicenseWithCardRequest,
  LicenseDetailResponse,
  ValidateLicenseCardRequest,
  ValidateLicenseCardResponse
} from '../components/common/Types';

export const useLicenseById = (licenseId: string) => {
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;
  return useQuery({
    queryKey: ['license', licenseId],
    queryFn: () => fetchLicenseById(branchId ?? '', licenseId),
    staleTime: 60000,
    enabled: !!licenseId
  });
};

export const useLicenseByIdWithPolling = (licenseId: string) => {
  const startTimeRef = useRef(Date.now());
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useQuery<LicenseDetailResponse>({
    queryKey: ['license', licenseId],
    queryFn: async () => {
      const data = await fetchLicenseById(branchId ?? '', licenseId);
      if (!data.responseKey && Date.now() - startTimeRef.current > 30000) {
        throw new Error('Timeout: responseKey not received');
      }
      return data;
    },
    staleTime: 0,
    // This method is only used in ResponseKeyStep.
    // ResponseKeyStep is the screen that appears after the Stripe checkout session ends.
    // If the branchId is not available when calling the API, it will return a 403 error, so the API should not be executed until branchId is retrieved.
    enabled: !!licenseId && !!branchId,

    refetchInterval: (q): number | false => {
      if (q.state.error) return false;

      const data = q.state.data;
      if (!data || !data.responseKey) {
        if (Date.now() - startTimeRef.current > 30000) {
          return false;
        }
        return 2000;
      }
      return false;
    },

    retry: 0
  });
};

export const useValidateLicenseCard = (
  onSuccessCallback?: (data: ValidateLicenseCardResponse) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const mutationOptions: UseMutationOptions<
    ValidateLicenseCardResponse,
    Error,
    {
      aiphoneBranchPublicId: string;
      data: ValidateLicenseCardRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, data }) => {
      return await validateLicenseCard(aiphoneBranchPublicId, data);
    },
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};

export const useCreateActivatedLicenseWithCard = (
  onSuccessCallback?: () => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const mutationOptions: UseMutationOptions<
    LicenseDetailResponse,
    Error,
    {
      aiphoneBranchPublicId: string;
      deploymentId: string;
      data: CreateActivatedLicenseWithCardRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, data }) => {
      return await createActivatedLicenseWithCard(aiphoneBranchPublicId, deploymentId, data);
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};
