import { useMutation } from '@tanstack/react-query';
import { cancelStripeSubscription } from '../lib/api/LicenseManagementApi';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';

export const useCancelStripeSubscription = () => {
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;
  const mutation = useMutation({
    mutationFn: (subscriptionId: string) => cancelStripeSubscription(subscriptionId, branchId)
  });

  return mutation;
};
