import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// #region import LicenseManagement
// Hooks
import { useValidateLicenseCard } from 'features/LicenseManagement/hooks/useLicense';
// SharedComponents
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { handleExit } from 'features/LicenseManagement/components/common/navigationUtils';
// Types
import { DeploymentType, Product } from 'features/LicenseManagement/components/common/Types';
// #endregion

interface PrepaidPaymentViewProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  licenseCode: string;
  setLicenseCode: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product>>;
}

export const PrepaidPaymentView: React.FC<PrepaidPaymentViewProps> = ({
  setActiveStep,
  licenseCode,
  setLicenseCode,
  setSelectedProduct
}) => {
  const { t } = useTranslation('licenseManagement');
  const licenseCardImageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/LicenseCard.png`;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { currentBranch } = useReduxBranches();
  const aiphoneBranchPublicId = currentBranch?.publicId ?? '';
  const { mutate: validateLicenseCard, isPending } = useValidateLicenseCard(
    (data) => {
      const { packageId, duration, licenseDescription, maxDoorCount, doorSize } = data;

      setSelectedProduct({
        package: packageId,
        duration,
        description: licenseDescription,
        maxDoorCount,
        doorSize,
        deploymentType: DeploymentType.Local
      });

      setActiveStep((prev) => prev + 1);
    },
    (error: any) => {
      setErrorMessage(error.message);
    }
  );

  const handleContinue = () => {
    validateLicenseCard({ aiphoneBranchPublicId, data: { licenseCode } });
  };

  return (
    <Box sx={styles.contentSection}>
      <Box sx={styles.textSection}>
        <Typography variant="body1" paragraph>
          {t('Enter_License_Card_Code')}
        </Typography>
        <FormControl sx={styles.form}>
          <InputLabel htmlFor="license-code" sx={styles.inputLabel}>
            {t('Enter_Code')}
          </InputLabel>
          <OutlinedInput
            id="license-code"
            label="{t('Enter_License_Card_Code')}"
            value={licenseCode}
            onChange={(e) => setLicenseCode(e.target.value)}
            sx={styles.textField}
          />
        </FormControl>
        <Box component="img" src={licenseCardImageUrl} alt="License Card" sx={styles.licenseCardImage} />
        <Box sx={styles.buttonContainer}>
          <Button onClick={handleExit} style={styles.saveButton}>
            {t('Exit')}
          </Button>
          <LoadingButton onClick={handleContinue} style={styles.continueButton} disabled={!licenseCode}>
            {t('Continue')}
          </LoadingButton>
        </Box>
        {isPending && <LoadingSpinner />}
        {errorMessage && <ErrorAlert errorMessage={errorMessage} customMessage={t('Error_Validating_License_Card')} />}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1
  },
  licenseCardImage: {
    maxHeight: '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    Padding: '10px',
    marginBottom: '20px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentSection: {
    flex: 1,
    display: 'flex'
  },
  textSection: {
    flex: 1,
    padding: 2
  },
  textField: {
    width: '40%',
    minWidth: '200px'
  },
  inputLabel: {
    backgroundColor: 'white',
    padding: '0 5px',
    marginLeft: '-4px'
  },
  continueButton: {
    padding: '10px',
    backgroundColor: '#003366',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  saveButton: {
    padding: '10px',
    color: '#003366',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  buttonContainer: {
    display: 'flex',

    justifyContent: 'flex-end',
    gap: 2,
    marginBottom: 5,
    flex: 1
  }
};
