import { useEffect, useState, useRef } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryResult,
  useInfiniteQuery,
  InfiniteData
} from '@tanstack/react-query';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { EnumList, fetchEnumList, IStateValue } from 'shared/utils/EnumUtils';
import {
  deleteDeployment,
  fetchDeploymentById,
  createCloudDeployment,
  createLocalDeployment,
  updateDeploymentByDeploymentId,
  createAiphoneToHartmannBranchMapping,
  fetchDeploymentsForBranch
} from '../lib/api/LicenseManagementApi';
import {
  AiphoneBranchToHartmannBranchMapping,
  DeploymentType,
  DeleteDeploymentArgs,
  CreateDeploymentRequest,
  CreateDeploymentResponse,
  initializeCreateDeploymentRequest,
  DeploymentDetailResponse,
  CreateHartmannBranchRequest,
  DeploymentPage
} from '../components/common/Types';
import { DEPLOYMENTS_PAGE_SIZE_DEFAULT } from '../components/deployment/DeploymentList/DeploymentDataGrid';

interface PageParam {
  key: string | null;
  size: number;
}

export const useDeleteDeployment = () => {
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;
  const queryClient = useQueryClient();
  const mutationOptions: UseMutationOptions<unknown, Error, DeleteDeploymentArgs> = {
    mutationFn: deleteDeployment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deployments', branchId] });
    }
  };

  return useMutation(mutationOptions);
};

export const useDeployments = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useInfiniteQuery<DeploymentPage, Error, InfiniteData<DeploymentPage>, [string, string | undefined], PageParam>(
    {
      queryKey: ['deployments', branchId],
      queryFn: async ({ pageParam }: { queryKey: [string, string | undefined]; pageParam: PageParam }) => {
        if (!branchId) {
          return Promise.reject(new Error('No branch ID available'));
        }

        const response = await fetchDeploymentsForBranch(
          branchId,
          pageParam?.key ?? null,
          pageParam?.size ?? DEPLOYMENTS_PAGE_SIZE_DEFAULT
        );

        return {
          deployments: response.deployments || [],
          lastEvaluatedKey: response.lastEvaluatedKey || undefined,
          totalCount: response.totalCount || 0
        };
      },
      getNextPageParam: (lastPage) =>
        lastPage.lastEvaluatedKey ? { key: lastPage.lastEvaluatedKey, size: DEPLOYMENTS_PAGE_SIZE_DEFAULT } : undefined,
      initialPageParam: { key: null, size: DEPLOYMENTS_PAGE_SIZE_DEFAULT },
      staleTime: 60000,
      enabled: enabled && !!branchId
    }
  );
};

export const useDeploymentById = (
  deploymentId: string,
  isFromStripeCheckout = false
): UseQueryResult<DeploymentDetailResponse> => {
  const startTimeRef = useRef(Date.now());
  const { currentUser } = useReduxUsers();
  const userId = currentUser?.publicId;
  const roleList = currentUser?.permissions.global.roleList;
  const isRoleListNull = roleList == null;
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useQuery<DeploymentDetailResponse>({
    queryKey: ['deployment', deploymentId],
    queryFn: async () => {
      const data = await fetchDeploymentById(branchId ?? '', deploymentId);
      if (isFromStripeCheckout && !data?.licensePublicId && Date.now() - startTimeRef.current > 30000) {
        throw new Error('Timeout: Deployment Info not received');
      }
      return data;
    },
    staleTime: 0,
    enabled: !!deploymentId && !!userId && (!isRoleListNull || !!branchId),

    refetchInterval: isFromStripeCheckout
      ? (q): number | false => {
          if (q.state.error) return false;

          const data = q.state.data;
          if (!data?.licensePublicId) {
            if (Date.now() - startTimeRef.current > 30000) {
              return false;
            }
            return 1000;
          }
          return false;
        }
      : undefined,

    retry: 0
  });
};

export const useDeploymentByIdPolling = (deploymentId: string): UseQueryResult<DeploymentDetailResponse> => {
  const startTimeRef = useRef(Date.now());
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;
  return useQuery<DeploymentDetailResponse>({
    queryKey: ['deployment', deploymentId],
    queryFn: async () => {
      const data = await fetchDeploymentById(branchId ?? '', deploymentId);
      if (!data?.licensePublicId && Date.now() - startTimeRef.current > 30000) {
        throw new Error('Timeout: License Info not received');
      }
      return data;
    },
    staleTime: 0,
    // This method is only used in ResponseKeyStep.
    // ResponseKeyStep is the screen that appears after the Stripe checkout session ends.
    // If the branchId is not available when calling the API, it will return a 403 error, so the API should not be executed until branchId is retrieved.
    enabled: !!deploymentId && !!branchId,

    refetchInterval: (q): number | false => {
      if (q.state.error) return false;

      const data = q.state.data;
      if (!data?.licensePublicId) {
        if (Date.now() - startTimeRef.current > 30000) {
          return false;
        }
        return 1000;
      }
      return false;
    },

    retry: 0
  });
};

export const useCreateDeployment = (
  data: CreateDeploymentRequest,
  onSuccessCallback?: (response: CreateDeploymentResponse) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const { currentBranch } = useReduxBranches();
  const { currentUser } = useReduxUsers();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const queryClient = useQueryClient();

  const [updatedData, setUpdatedData] = useState<CreateDeploymentRequest>(initializeCreateDeploymentRequest);
  useEffect(() => {
    const emailRecipients = data.deployment.emailRecipients;
    const currentUserEmail = currentUser?.email ?? '';
    const updatedEmailRecipients = emailRecipients.includes(currentUserEmail)
      ? emailRecipients
      : [...emailRecipients, currentUserEmail];
    const newData = {
      ...data,
      address: {
        ...data.address,
        // When executing the Hartmann API, “United States of America” causes an error, so it will be converted to “United States”.
        countryName:
          data.address.countryName === 'United States of America' ? 'United States' : data.address.countryName
      },
      deployment: {
        ...data.deployment,
        // When deployment.type is local, set EULA to true
        // because installing AC NIO software requires EULA acceptance.
        EULA: data.deployment.type === DeploymentType.Local ? true : data.deployment.EULA,
        emailRecipients: updatedEmailRecipients
      }
    };

    setUpdatedData(newData);
  }, [data, queryClient]);

  // #region Function to create a new Hartmann branch mapping
  const createHartmannBranchMapping = async () => {
    const request: CreateHartmannBranchRequest = {
      aiphoneBranchPublicId: currentBranch?.publicId ?? '',
      branchName: currentBranch?.branchName ?? '',
      email: currentUser?.email ?? '',
      address: currentBranch?.address ?? '',
      city: currentBranch?.city ?? '',
      postalCode: currentBranch?.postalCode ?? '',
      // When executing the Hartmann API, “United States of America” causes an error, so it will be converted to “United States”.
      country: currentBranch?.stateId
        ? enumList.country[(enumList.state[currentBranch.stateId] as IStateValue).countryId].value ===
          'United States of America'
          ? 'United States'
          : enumList.country[(enumList.state[currentBranch.stateId] as IStateValue).countryId].value
        : '',
      region: currentBranch?.stateId ? (enumList.state[currentBranch.stateId] as IStateValue).value : ''
    };
    const result = await createAiphoneToHartmannBranchMapping(request);
    queryClient.setQueryData(['aiphoneBranchToHartmannBranchMapping', currentBranch?.publicId], result);
    return result?.hartmannBranchId;
  };
  // #endregion

  // #region Handles the creation of a new deployment
  const mutationFn = async () => {
    const cachedData: AiphoneBranchToHartmannBranchMapping | undefined = queryClient.getQueryData([
      'aiphoneBranchToHartmannBranchMapping',
      currentBranch?.publicId
    ]);

    if (!cachedData) {
      throw new Error('No cached data found');
    }
    if (cachedData.hartmannBranchId === null || cachedData.hartmannBranchId === undefined) {
      updatedData.hartmannBranchId = (await createHartmannBranchMapping()) ?? 0;
    } else {
      updatedData.hartmannBranchId = cachedData.hartmannBranchId;
    }

    switch (updatedData.deployment.type) {
      case DeploymentType.Cloud:
        return await createCloudDeployment(currentBranch?.publicId, updatedData);
      case DeploymentType.Local:
        return await createLocalDeployment(currentBranch?.publicId, updatedData);
      default:
        throw new Error('Unknown deployment type');
    }
  };
  // #endregion

  return useMutation({
    mutationFn,
    onSuccess: (response: CreateDeploymentResponse) => {
      queryClient.invalidateQueries({ queryKey: ['deployments', currentBranch?.publicId] });
      if (onSuccessCallback) {
        onSuccessCallback(response);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  });
};

export const useUpdateDeploymentByDeploymentId = (deploymentId: any, formData: any, options: any) => {
  const queryClient = useQueryClient();
  const { currentBranch } = useReduxBranches();
  const branchId = currentBranch?.publicId;

  return useMutation({
    mutationFn: () => updateDeploymentByDeploymentId(branchId ?? '', deploymentId, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deployments', currentBranch?.publicId] });
      if (options.onSuccess) {
        options.onSuccess();
      }
    }
  });
};
