import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Button,
  Box,
  Typography,
  CircularProgress,
  IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

// #region import LicenseManagement
// Styles
// Types
import { DeploymentType, CreateDeploymentRequest } from '../../../common/Types';
// #endregion

import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { getFilteredStateList } from 'features/LicenseManagement/components/common/utils';
import { getCountryOptions } from '../../../common/GetCountryOptions';
import DeploymentNotificationsField from './NotificationField';
import { EMAIL_REGEX } from 'shared/constants/regex';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';

// #region Interfaces
/**
 * Props interface for CreateDeploymentInformationModal
 */
interface CreateDeploymentInformationModalProps {
  /**
   * Indicates whether the modal is open.
   * This modal is for entering the deployment type information.
   */
  open: boolean;
  /**
   * Callback to open the previous modal (type modal).
   */
  onOpenTypeModal: () => void;
  /**
   * Callback to close the modal.
   * @param resetForm Whether to reset the form or not.
   */
  onClose: (resetForm: boolean) => void;
  /**
   * Form data entered by the user.
   */
  formData: CreateDeploymentRequest;
  /**
   * Function to update the form data.
   */
  setFormData: React.Dispatch<React.SetStateAction<CreateDeploymentRequest>>;
  /**
   * Callback to create the deployment.
   */
  onCreateLocalDeployment: () => void;
  /**
   * Indicates whether the deployment is being created.
   */
  isPending: boolean;
}
// #endregion

export const initialErrors = {
  deployment: {
    name: false,
    emailRecipients: false
  },
  tenant: {
    subdomain: false
  },
  address: {
    street: false,
    city: false,
    postalCode: false
  },
  contact: {
    name: false,
    emailAddress: false,
    phoneNumber: false
  }
};

const CreateDeploymentInformationModal = (props: CreateDeploymentInformationModalProps) => {
  const { open, onOpenTypeModal, onClose, formData, setFormData, onCreateLocalDeployment, isPending } = props;
  const siteSelected = formData.linkedAiphoneSitePublicId !== null;

  // #region Translation
  const { t } = useTranslation('licenseManagement');
  // #endregion

  // #region State Management
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [countryId, setCountryId] = useState('2');
  const [errors, setErrors] = useState(initialErrors);

  //Fetch enumlist when component mounts
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  // Update countryId when countryName changes
  useEffect(() => {
    if (formData.address.countryName === 'Canada') {
      setCountryId('3');
    } else {
      setCountryId('2');
    }
  }, [formData.address.countryName]);

  // #endregion

  // #region Handlers
  const validateField = (name: string, value: string) => {
    const [topLevelKey, nestedKey] = name.split('.') as [keyof typeof errors, string];
    let hasError = false;
    if (topLevelKey === 'contact' && nestedKey === 'emailAddress') {
      hasError = value.trim() === '' || !EMAIL_REGEX.test(value);
    } else if (nestedKey !== 'description' && nestedKey !== 'aptSuiteBuilding') {
      hasError = value.trim() === '';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [topLevelKey]: {
        ...prevErrors[topLevelKey],
        [nestedKey]: hasError
      }
    }));
  };

  const handleNestedFieldChange = (name: string, value: string) => {
    const [topLevelKey, nestedKey] = name.split('.') as [keyof CreateDeploymentRequest, string];
    setFormData((prev) => {
      const topLevelValue = prev[topLevelKey];
      if (typeof topLevelValue !== 'object' || topLevelValue === null) {
        return prev;
      }
      return {
        ...prev,
        [topLevelKey]: {
          ...topLevelValue,
          [nestedKey]: value
        }
      };
    });
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = value;
    switch (name) {
      case 'contact.phoneNumber':
        newValue = value.replace(/[^0-9+]/g, '');
        break;
      case 'contact.emailAddress':
        newValue = value.replace(/[^\w\s@.+]/gi, '');
        break;
      default:
        newValue = value.replace(/[^\w\s@]/gi, '');
        break;
    }
    handleNestedFieldChange(name, newValue);
    validateField(name, newValue);
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    const selectedCountry = event.target.value as string;
    const newCountryId = selectedCountry === 'United States of America' ? '2' : '3';
    setCountryId(newCountryId);

    setFormData((prevData: CreateDeploymentRequest) => ({
      ...prevData,
      address: {
        ...prevData.address,
        countryName: selectedCountry,
        stateName: '-'
      }
    }));
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    const selectedState = event.target.value as string;

    setFormData((prevData: CreateDeploymentRequest) => ({
      ...prevData,
      address: {
        ...prevData.address,
        stateName: selectedState
      }
    }));
  };

  const handleBack = () => {
    onClose(false);
    onOpenTypeModal();
  };

  const handleCreateDeployment = () => {
    onCreateLocalDeployment();
  };

  const isFormValid = () => {
    const isRequiredFieldsValid =
      formData.deployment.name.trim() !== '' &&
      formData.address.street.trim() !== '' &&
      formData.address.city.trim() !== '' &&
      formData.address.postalCode.trim() !== '' &&
      formData.contact.name.trim() !== '' &&
      formData.contact.emailAddress.trim() !== '' &&
      formData.contact.phoneNumber.trim() !== '';
    const isSubdomainValid =
      formData.deployment.type !== DeploymentType.Cloud || formData.tenant.subdomain.trim() !== '';
    const isStateNameValid = formData.address.stateName.trim() !== '-';
    const areAllErrorsFalse = Object.values(errors).every((nestedObj) =>
      Object.values(nestedObj).every((val) => val === false)
    );
    return isRequiredFieldsValid && isSubdomainValid && isStateNameValid && areAllErrorsFalse;
  };

  const getStateList = (countryId: string) => {
    return getFilteredStateList(countryId, enumList, (state) => (
      <MenuItem key={state.value} value={state.value}>
        {state.value}
      </MenuItem>
    ));
  };

  const handleEmailRecipientsChange = (newRecipients: string[]) => {
    setFormData((prev) => ({
      ...prev,
      deployment: {
        ...prev.deployment,
        emailRecipients: newRecipients
      }
    }));
  };

  // #endregion

  // #region Render
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (isPending && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
          // Do not close the modal while the API is executing
          return;
        }
        setErrors(initialErrors);
        onClose(true);
      }}
      disableEscapeKeyDown={isPending} // Prevent closing the modal with the escape key while API is executing
      PaperProps={{
        style: styles.modalContainer
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={styles.moduleTitle}>{t('Deployment_Details_Title')}</Typography>
        <IconButton
          color="default"
          aria-label="cancel"
          disabled={isPending}
          onClick={() => onClose(true)}
          style={{ marginRight: '1rem' }}
        >
          <CancelIcon />
        </IconButton>
      </Box>
      <Typography style={styles.moduleSecondaryTitle}>{t('Deployment_Info_Subheader')}</Typography>
      <Box style={styles.divider} />
      <DialogContent style={styles.moduleContent}>
        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Enter_Info')}
        </Typography>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            required
            label="Deployment Name"
            name="deployment.name"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.deployment.name}
            onChange={handleTextFieldChange}
            error={!!errors.deployment.name}
            helperText={errors.deployment.name ? t('Required_Field') : ''}
          />
        </FormControl>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            label="Description"
            name="deployment.description"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.deployment.description}
            onChange={handleTextFieldChange}
          />
        </FormControl>
        {formData.deployment.type === DeploymentType.Cloud && (
          <FormControl fullWidth style={styles.inputBoxMarginBottom}>
            <Box display="flex" alignItems="center">
              <TextField
                required
                label="Subdomain"
                name="tenant.subdomain"
                sx={{ ...styles.textField, width: '420px' }}
                InputProps={{
                  inputProps: {
                    maxLength: 10
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={formData.tenant.subdomain}
                onChange={handleTextFieldChange}
                error={!!errors.tenant.subdomain}
                helperText={errors.tenant.subdomain ? t('Required_Field') : ''}
              />
              <Typography variant="body1" sx={{ marginLeft: '0.5rem' }}>
                .aiphone.cloud
              </Typography>
            </Box>
          </FormControl>
        )}

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label="Address"
              name="address.street"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.address.street}
              onChange={handleTextFieldChange}
              error={!!errors.address.street}
              helperText={errors.address.street ? t('Required_Field') : ''}
              InputProps={{
                readOnly: siteSelected
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Apt/Suite/Building"
              name="address.aptSuiteBuilding"
              sx={styles.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.address.aptSuiteBuilding}
              onChange={handleTextFieldChange}
            />
          </FormControl>
        </Box>

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <InputLabel id="country-select-label">Country *</InputLabel>
            <Select
              labelId="country-select-label"
              value={formData.address.countryName}
              onChange={handleCountryChange}
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              label="Country *"
              disabled={siteSelected}
            >
              {getCountryOptions(enumList).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="state-select-label">State *</InputLabel>
            <Select
              labelId="state-select-label"
              value={formData.address.stateName}
              onChange={handleStateChange}
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              label="State *"
              disabled={siteSelected}
            >
              <MenuItem key="Select_State" value="-" disabled={true}>
                -
              </MenuItem>
              {getStateList(countryId)}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label="City"
              name="address.city"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: siteSelected
              }}
              value={formData.address.city}
              onChange={handleTextFieldChange}
              error={!!errors.address.city}
              helperText={errors.address.city ? t('Required_Field') : ''}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              required
              label="Postal Code"
              name="address.postalCode"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: siteSelected
              }}
              value={formData.address.postalCode}
              onChange={handleTextFieldChange}
              error={!!errors.address.postalCode}
              helperText={errors.address.postalCode ? t('Required_Field') : ''}
            />
          </FormControl>
        </Box>

        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Enter_Contact_Info')}
        </Typography>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            label="Company Name"
            name="contact.companyName"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.contact.companyName}
            onChange={handleTextFieldChange}
          />
        </FormControl>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            required
            label="Representative Name"
            name="contact.name"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.contact.name}
            onChange={handleTextFieldChange}
            error={!!errors.contact.name}
            helperText={errors.contact.name ? t('Required_Field') : ''}
          />
        </FormControl>
        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label="Email"
              name="contact.emailAddress"
              sx={styles.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.contact.emailAddress}
              onChange={handleTextFieldChange}
              error={!!errors.contact.emailAddress}
              helperText={errors.contact.emailAddress ? t('Required_Field_Email') : ''}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              required
              label="Phone Number"
              name="contact.phoneNumber"
              sx={styles.textField}
              InputProps={{
                inputProps: {
                  maxLength: 10
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.contact.phoneNumber}
              onChange={handleTextFieldChange}
              error={!!errors.contact.phoneNumber}
              helperText={errors.contact.phoneNumber ? t('Required_Field') : ''}
            />
          </FormControl>
        </Box>
        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Notifications')}
        </Typography>
        <Typography style={{ ...styles.moduleSubTitleInfo, marginBottom: '0.5rem' }}>
          {t('Deployment_Notifications_Subheader')}
        </Typography>

        <DeploymentNotificationsField
          label={t('Notifications_To')}
          name="deployment.emailRecipients"
          value={formData.deployment.emailRecipients || []}
          onChange={handleEmailRecipientsChange}
          error={errors.deployment.emailRecipients}
          setErrors={setErrors}
          helperText={errors.deployment.emailRecipients ? t('Required_Field_Email') : ''}
        />

        <Box style={LicenseManagementCommonStyles.buttonContainer}>
          <Button
            variant="text"
            sx={LicenseManagementCommonStyles.buttonBack}
            onClick={handleBack}
            disabled={isPending}
          >
            {t('Back')}
          </Button>
          <Button
            variant="contained"
            sx={{ ...LicenseManagementCommonStyles.button, width: '170px' }}
            disabled={!isFormValid() || isPending}
            onClick={handleCreateDeployment}
          >
            {isPending ? <CircularProgress size={24} color="inherit" /> : t('Create_Deployment')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
  // #endregion
};

// #region Styles
const styles = {
  modalContainer: {
    width: '60%',
    minWidth: '750px',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  moduleContent: {
    padding: '1.5rem'
  },
  moduleTitle: {
    fontSize: '1.5rem',
    margin: '0.8rem 0rem 0rem 1.5rem',
    fontWeight: 'bold'
  },
  moduleSecondaryTitle: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0rem 0rem 0.3rem 1.5rem'
  },
  divider: {
    height: '1px',
    backgroundColor: '#e0e0e0',
    margin: '0 0.5rem'
  },
  moduleSubTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#444'
  },
  moduleSubTitleInfo: {
    fontSize: '1rem',
    color: '#444'
  },
  textField: {
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    },
    '& input:-webkit-autofill': {
      borderRadius: '10px',
      height: '9px'
    }
  },
  textFieldReadOnly: {
    backgroundColor: '#f5f5f5'
  },
  inputBoxMarginBottom: {
    marginBottom: '1.6rem'
  }
};
// #endregion
export default CreateDeploymentInformationModal;
