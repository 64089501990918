import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HeaderWithBreadcrumbs from './HeaderWithBreadcrumbs';
import { LicenseStatus, DeploymentType } from './Types';

const Header = ({ deployment, status }) => {
  return (
    <Box sx={styles.header}>
      <HeaderWithBreadcrumbs title={deployment.name} />
      <Box sx={styles.headerContent}>
        {deployment?.deploymentType === DeploymentType.Cloud ? (
          <CloudUploadIcon sx={styles.icon} />
        ) : (
          <LocationOnIcon sx={styles.icon} />
        )}
        <Box>
          <Typography variant="h4">{deployment.name}</Typography>
          <Box component="span" sx={styles.licenseStatus(status)}>
            {status}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  header: {
    mb: 3
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    mb: 1
  },
  icon: {
    fontSize: 40,
    mr: 1,
    color: 'primary.main'
  },
  licenseStatus: (status: LicenseStatus) => ({
    backgroundColor:
      status === LicenseStatus.Unactivated ? '#FFE5B4' : status === LicenseStatus.Activated ? '#d1e7dd' : '#f8d7da',
    color:
      status === LicenseStatus.Unactivated ? '#FF8C00' : status === LicenseStatus.Activated ? '#0f5132' : '#842029',
    px: 2,
    py: 0.5,
    borderRadius: 1,
    fontSize: '0.875rem',
    display: 'inline-block',
    mt: 0.5
  })
};
export default Header;
