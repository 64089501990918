import { Box, Button, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateSiteMutation } from 'services/aiphoneCloud';
import { RootState, setIsWizardComplete } from 'store';

const strings = {
  title: 'Sync System Settings',
  body1:
    "You're all set! Click the button below to navigate to your site's home page to sync the settings with the devices.",
  siteInfoButton: 'Go to Site Info'
};

const SyncSystemSettings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const site = useSelector((state: RootState) => state.site);
  const [updateSite] = useUpdateSiteMutation();
  const dispatch = useDispatch();

  const renderSyncSystemSettingsEntry = () => {
    return (
      <Box sx={{ mt: 3 }}>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {strings.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {strings.body1}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleExitWizard = async () => {
    const updatedSite = {
      publicId: site.siteInfo.publicId,
      isWizardCompleted: true
    };
    await updateSite({ updatedSite });
    dispatch(setIsWizardComplete(true));
    navigate(`/site/${params.id}/siteinfo`);
  };

  return (
    <Container>
      {renderSyncSystemSettingsEntry()}
      <Box sx={styles.centerContent}>
        <Button onClick={handleExitWizard} variant="contained">
          {strings.siteInfoButton}
        </Button>
      </Box>
    </Container>
  );
};

export default SyncSystemSettings;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    marginTop: 1
  }
};
