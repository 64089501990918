import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HeaderWithBreadcrumbsProps {
  title: string;
}

const HeaderWithBreadcrumbs = ({ title }: HeaderWithBreadcrumbsProps) => {
  const { t } = useTranslation('licenseManagement');

  return (
    <Box sx={styles.navigationHeader}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/licensemanagement">
          {t('License_Management')}
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
    </Box>
  );
};

const styles = {
  navigationHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2
  }
};

export default HeaderWithBreadcrumbs;
