import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import {
  DeploymentDetailResponse,
  DeploymentType,
  LicenseStatus
} from 'features/LicenseManagement/components/common/Types';
import { useLicenseById } from 'features/LicenseManagement/hooks/useLicense';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import FieldDisplay from 'features/LicenseManagement/components/common/Fielddisplay';
import { formatDate } from 'features/LicenseManagement/components/common/utils';
import { usePermission } from 'context/PermissionContext';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { PermissionsContextType } from 'permissions/utils';
import { Edit, Sync } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// #region import LicenseManagement

// #endregion

interface ActivatedLicenseDetailProps {
  deployment: DeploymentDetailResponse;
}

export const ActivatedLicenseDetail: React.FC<ActivatedLicenseDetailProps> = ({ deployment }) => {
  const { t } = useTranslation('licenseManagement');
  const { isAllowedTo } = usePermission();
  const { currentBranch } = useReduxBranches();
  const navigate = useNavigate();

  const canEditBilling = isAllowedTo('deploymentBilling:edit', currentBranch?.publicId, PermissionsContextType.BRANCH);

  const {
    data: license,
    isLoading,
    error,
    isError: isFetchLicenseError,
    refetch: refetchLicense
  } = useLicenseById(deployment.licensePublicId);

  if (isFetchLicenseError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={error.message}
          customMessage={t('Error_Loading_License_Details')}
          onRetry={refetchLicense}
        />
      </Box>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderField = (label: string, value: any, isLast?: boolean) => (
    <FieldDisplay label={t(label)} value={value} isLast={isLast} />
  );

  const handleRenewLocalLicense = () => {
    navigate(`/licensemanagement/${deployment.deploymentId}/licenseDetails`, {
      state: { renewLocalLicense: true }
    });
  };

  return (
    <Box>
      <Box sx={styles.sectionContainerStyle}>
        <Typography variant="h6" gutterBottom>
          {t('License_Details')}
        </Typography>
        {canEditBilling &&
          deployment.licenseStatus === LicenseStatus.Expired &&
          deployment.deploymentType === DeploymentType.Local && (
            <Button startIcon={<Sync />} onClick={handleRenewLocalLicense} sx={styles.button} aria-label="edit">
              {t('Renew_License')}
            </Button>
          )}
        <Box sx={styles.sectionStyle}>
          {deployment.deploymentType === DeploymentType.Local && (
            <>
              {renderField('Account_No', deployment.hartmannAccountNumber)}
              {renderField('License_Package', license.name)}
            </>
          )}
          {renderField('Activation_Date', formatDate(license.activationDate))}
          {renderField('Expiration_Date', formatDate(license.expirationDate), true)}
        </Box>
      </Box>
      <Box sx={styles.sectionContainerStyle}>
        <Typography variant="h6" gutterBottom>
          {t('Package_Details')}
        </Typography>
        {canEditBilling &&
          deployment.licenseStatus !== LicenseStatus.Expired &&
          deployment.deploymentType === DeploymentType.Local && (
            <Button startIcon={<Edit />} onClick={handleRenewLocalLicense} sx={styles.button} aria-label="edit">
              {t('Edit_Package_Details')}
            </Button>
          )}
        <Box sx={styles.sectionStyle}>
          {renderField('Doors', license?.numOfDoors)}
          {deployment.deploymentType === DeploymentType.Local && (
            <>
              {renderField('Cameras', license.numOfCameras)}
              {renderField('Partitions', t('Unlimited'))}
              {renderField('Elevators', t('Available'))}
              {renderField('IO', t('Available'))}
              {renderField('LDAP', t('Available'))}
              {renderField('Unmanaged_Doors', t('Available'), true)}
            </>
          )}
          {deployment.deploymentType === DeploymentType.Cloud && (
            <>
              {renderField('I/O_Expanders', license.numOfExpanders)}
              {renderField('Elevator_Cabs', license.numOfElevators, true)}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  button: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#0071CE',
    textTransform: 'uppercase'
  }
};
