import { Box, MenuItem, TextField, Typography } from '@mui/material';

interface FieldDisplayProps {
  label: string;
  value: string | React.ReactNode;
  isEditing?: boolean;
  readonly?: boolean;
  onChange?: (value: string) => void;
  isLast?: boolean;
  options?: { label: string | React.ReactNode; value: string }[];
}

const FieldDisplay = ({ label, value, isEditing, readonly, onChange, isLast = false, options }: FieldDisplayProps) => (
  <Box mt={2} sx={styles.field(isLast)}>
    {isEditing && !readonly && options && options.length > 0 ? (
      <TextField
        select
        value={value || ''}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        fullWidth
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    ) : isEditing && !readonly ? (
      <TextField defaultValue={value} onBlur={(e) => onChange && onChange(e.target.value)} fullWidth />
    ) : (
      <Typography variant="body1">{value}</Typography>
    )}
    <Typography variant="body1" style={{ color: 'gray' }}>
      {label}
    </Typography>
  </Box>
);

const styles = {
  field: (isLast: boolean) => ({
    pb: 2,
    borderBottom: isLast ? 'none' : '1px solid #e0e0e0'
  })
};

export default FieldDisplay;
