import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import { DeploymentDoorSize, PaymentMethod, steps } from 'features/LicenseManagement/components/common/Types';
import MethodStep from './MethodStep';
import ActivationStep from './ActivationStep';
import { DeploymentSteps } from 'features/LicenseManagement/components/common/DeploymentSteps';

interface UnactivatedLocalLicenseProps {
  accountNumber: number;
  deployment: any;
}

export const UnactivatedLocalLicense: React.FC<UnactivatedLocalLicenseProps> = ({ accountNumber, deployment }) => {
  const { t } = useTranslation('licenseManagement');
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethod.Online);
  const [planType, setPlanType] = useState(DeploymentDoorSize.Basic);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [licenseCode, setLicenseCode] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null); // Track selected product
  const { isAllowedTo } = usePermission();
  const { currentBranch } = useReduxBranches();
  // TODO update to correct permission
  const hasManageBillingPermission = isAllowedTo(
    'deploymentBilling:edit',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  // Function to render content based on the current step
  const renderStepContent = () => {
    const stepComponents = [
      <MethodStep
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        planType={planType}
        setPlanType={setPlanType}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        licenseCode={licenseCode}
        setLicenseCode={setLicenseCode}
        setSelectedProduct={setSelectedProduct}
      />,
      <ActivationStep
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        accountNumber={accountNumber}
        selectedProduct={selectedProduct}
        deployment={deployment}
        selectedPaymentMethod={selectedPaymentMethod}
        licenseCode={licenseCode}
      />
    ];
    return stepComponents[activeStep] || stepComponents[0];
  };

  return (
    <Box sx={styles.container}>
      {hasManageBillingPermission ? (
        <>
          <Typography variant="h6" sx={styles.title}>
            {t('Finalize_License_Deployment')}
          </Typography>
          <DeploymentSteps steps={steps(selectedPaymentMethod)} activeStep={activeStep} />
          {renderStepContent()}
        </>
      ) : (
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {t('Deployment_Has_No_Associated_License')}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: 2,
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
    alignItems: 'flex-start',
    height: '100%'
  },
  title: {
    margin: 0,
    padding: 0
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
};
