import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material';
import { CA, US } from 'country-flag-icons/react/3x2';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import CancelIcon from '@mui/icons-material/Cancel';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, resetGatewayState, setCurrentUser, updateGWId, updateSite } from 'store';
import { fetchAclSiteId } from 'shared/utils/fetchAclSiteId';
import {
  useCreateSiteMutation,
  useLazyGetUserWithPublicIdQuery,
  useGetRemoteManagementUtilMutation
} from 'services/aiphoneCloud';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useAclAuth } from 'features/SimBilling/Hooks';
import { GetUserInfo } from 'shared/api/Acl/IxgAclApi';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { t } from 'i18next';

interface INewSiteDialog {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormValues {
  siteName: string | undefined;
  siteAddress: string;
  siteCity: string;
  cityOptions?: string[];
  stateId: string;
  siteZip: string;
  timezone: number;
  countryId: string;
  sitePhoneNumber: string;
  typeId: number;
  newProperty: boolean;
  newPropertyId: string;
  systemId: string;
  systemPassword: string;
}

const initialValues: IFormValues = {
  siteName: '',
  siteAddress: '',
  siteCity: '',
  cityOptions: [] as string[],
  stateId: '',
  siteZip: '',
  timezone: 7,
  countryId: '',
  sitePhoneNumber: '',
  typeId: 0,
  newProperty: true,
  newPropertyId: '',
  systemId: '',
  systemPassword: ''
};

interface SiteInformationProps {
  formikProps: FormikProps<IFormValues>;
  aclSites?: string[];
}

const steps = ['Site Information', 'Site Type', 'Confirmation'];

enum Steps {
  SiteInformation,
  SiteType,
  Confirmation
}

//TODO: Move this to a utility file
const getStateList = (enumList: EnumList, countryId: string, selectCountryText: string) => {
  const stateList = Object.keys(enumList.state)
    .map((key) => {
      const stateWalker = enumList.state[key] as IStateValue;
      // If the state is not in the selected country, do not add the option
      // Also do not include the unknown option
      if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
        return null;
      }
      return (
        <MenuItem key={stateWalker.value} value={key}>
          {stateWalker.value}
        </MenuItem>
      );
    })
    .filter((val) => val !== null);

  if (stateList.length === 0) {
    stateList.push(
      <MenuItem key={selectCountryText} value="" disabled={true}>
        {selectCountryText}
      </MenuItem>
    );
  }

  return stateList;
};

const SiteInformation: React.FC<SiteInformationProps> = ({ formikProps }) => {
  const [enumList, setEnumList] = React.useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = React.useState(true);
  const [disabledCityState, setDisabledCityState] = React.useState(true);
  const selectCountryText = getString('Select_Country');
  const selectStateText = getString('Select_State');
  const siteInformation = getString('Title_SiteInformation');
  const siteInformationDialog = getString('SiteInformation_Dialog');
  const timezoneTitle = getString('Timezone_Title');

  const { values, handleChange, setFieldValue, errors, touched } = formikProps;

  // Use the query hook with skip: true so it doesn't run automatically
  const [fetchLocationByZipCode] = useGetRemoteManagementUtilMutation();

  const getStateId = (stateName: string) => {
    const stateKeys = Object.keys(enumList.state);
    for (const key of stateKeys) {
      if (enumList.state[key].value.toLowerCase() === stateName.toLowerCase()) {
        return key;
      }
    }
    return null;
  };

  React.useEffect(() => {
    const fetchEnums = async () => {
      const data = await fetchEnumList();
      setEnumList(data);
      setFetchingEnums(false);
    };

    fetchEnums();
  }, []);

  React.useEffect(() => {
    const fetchLocation = async () => {
      if (values.siteZip && values.countryId === '2' && values.siteZip.length === 5) {
        try {
          const response = await fetchLocationByZipCode({ country: values.countryId, zipCode: values.siteZip });
          const { data } = response;
          if (data) {
            setDisabledCityState(false);
            const stateId = getStateId(data.stateName);
            setFieldValue('stateId', stateId);
            setFieldValue('cityOptions', data.city);
            setFieldValue('siteCity', data.city[0]);
          }
        } catch (error) {
          setFieldValue('siteZip', '');
        }
      }
    };
    fetchLocation();
  }, [values.siteZip, setFieldValue]);

  return (
    <>
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Grid>
          <Box sx={styles.mb2}>
            <Typography variant="h5">{siteInformation}</Typography>
            <Typography variant="body1">{siteInformationDialog}</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="siteName"
                as={TextField}
                label={t('Site_Name')}
                fullWidth
                size="small"
                helperText={touched.siteName && errors.siteName}
                error={touched.siteName && !!errors.siteName}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <FormControl sx={styles.inputField} size="small" error={touched.countryId && Boolean(errors.countryId)}>
                <InputLabel id="country-label">{selectCountryText}</InputLabel>
                <Field
                  name="countryId"
                  id="countryId"
                  as={Select}
                  labelId="country-label"
                  label={selectCountryText}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('stateId', '');
                  }}
                >
                  {Object.keys(enumList.country).map((key) => {
                    const validCountries = ['CA', 'US'];
                    const countryWalker = enumList.country[key] as ICountryValue;

                    if (!validCountries.includes(countryWalker.alpha2Code)) {
                      return null;
                    }

                    const flagComponent: JSX.Element =
                      countryWalker.alpha2Code === 'CA' ? (
                        <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                      ) : (
                        <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                      );

                    return (
                      <MenuItem key={key} value={key}>
                        <Grid sx={styles.field}>
                          {flagComponent}
                          {countryWalker.value}
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </Field>
                <Box sx={styles.errorMessage}>
                  <ErrorMessage name="country" />
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Field
                name="siteZip"
                as={TextField}
                label={t('Zip_Code')}
                fullWidth
                size="small"
                helperText={touched.siteZip && errors.siteZip}
                error={touched.siteZip && !!errors.siteZip}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={styles.inputField} size="small" error={touched.timezone && Boolean(errors.timezone)}>
                <InputLabel id="timezone-label">{timezoneTitle}</InputLabel>
                <Field
                  name="timezone"
                  as={Select}
                  label={timezoneTitle}
                  labelId="timezone-label"
                  id="timezone"
                  fullWidth
                  size="small"
                >
                  {Object.keys(enumList.timezone).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {enumList.timezone[key].value}
                      </MenuItem>
                    );
                  })}
                </Field>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="siteAddress"
                as={TextField}
                label={t('Site_Address')}
                fullWidth
                size="small"
                helperText={touched.siteAddress && errors.siteAddress}
                error={touched.siteAddress && !!errors.siteAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={styles.inputField} size="small" error={touched.stateId && Boolean(errors.stateId)}>
                <InputLabel id="state-label">{selectStateText}</InputLabel>
                <Field
                  name="stateId"
                  id="stateId"
                  as={Select}
                  labelId="state-label"
                  label={selectStateText}
                  disabled={disabledCityState}
                >
                  {getStateList(enumList, values.countryId, selectCountryText)}
                </Field>
                <Box sx={styles.errorMessage}>
                  <ErrorMessage name="state" />
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={styles.inputField} size="small" error={touched.siteCity && Boolean(errors.siteCity)}>
                {values.countryId === '2' && <InputLabel id="siteCity-label">{t('Site_City')}</InputLabel>}
                <Field name="siteCity">
                  {({ field }: { field: any }) => {
                    return values.countryId === '2' ? (
                      <Select
                        {...field}
                        label={t('Site_City')}
                        labelId="siteCity-label"
                        fullWidth
                        onChange={(event) => {
                          setFieldValue('siteCity', event.target.value);
                        }}
                      >
                        {values.cityOptions && values.cityOptions.length > 0 ? (
                          (values.cityOptions || []).map((city, index) => (
                            <MenuItem key={index} value={city}>
                              {city}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            {t('No_Cities_Found')}
                          </MenuItem>
                        )}
                      </Select>
                    ) : (
                      <TextField
                        {...field}
                        label={t('Site_City')}
                        labelId="siteCity-label"
                        fullWidth
                        size="small"
                        onChange={(event) => {
                          setFieldValue('siteCity', event.target.value);
                        }}
                      />
                    );
                  }}
                </Field>
                {touched.siteCity && errors.siteCity && <FormHelperText>{errors.siteCity}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="sitePhoneNumber"
                as={TextField}
                label={t('Phone_Number')}
                fullWidth
                size="small"
                helperText={touched.sitePhoneNumber && errors.sitePhoneNumber}
                error={touched.sitePhoneNumber && !!errors.sitePhoneNumber}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const SiteType: React.FC<SiteInformationProps> = ({ formikProps, aclSites }) => {
  const { values, touched, errors, setFieldValue } = formikProps;
  const multiTenant = getString('Title_MultiTenant');
  const multiTenantDialog = getString('MultiTenant_Dialog');

  const commercial = getString('Title_Commercial');
  const commercialDialog = getString('Commercial_Dialog');

  const styles = {
    dividerContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%'
    },
    selectionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '1rem'
    },
    iconButton: {
      color: 'primary.main',
      fontSize: '5rem'
    },
    iconButtonSelected: {
      color: 'secondary.main',
      fontSize: '5rem'
    },
    typeSelection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      border: '1px solid #e0e0e0',
      width: '100%'
    },
    siteTypeCard: {
      width: '100%'
    },
    selectedSiteTypeCard: {
      width: '100%',
      border: '1px solid #0071ce',
      backgroundColor: '#f0f8ff'
    },
    inputField: {
      marginBottom: 1,
      width: '100%',
      '& .MuiInputBase-input': {
        backgroundColor: '#ffffff'
      },
      '&.MuiFormHelperText-root': {
        color: 'red'
      },
      '& .MuiInputLabel-root': {
        color: 'red',
        '&.Mui-focused': {
          color: 'black'
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey'
        },
        '&:hover fieldset': {
          borderColor: '#003366'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0071ce'
        }
      }
    }
  };

  const handleSiteTypeSelection = (selectedId: number) => {
    return () => {
      setFieldValue('typeId', selectedId);
    };
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <RadioGroup name="typeId" value={values.typeId}>
          <List>
            <ListItem>
              <Card sx={values.typeId === 2 ? styles.selectedSiteTypeCard : styles.siteTypeCard}>
                <CardActionArea sx={styles.typeSelection} disableRipple onClick={handleSiteTypeSelection(2)}>
                  <Box>
                    <Typography variant="body1">{multiTenant}</Typography>
                    <Typography variant="body2">{multiTenantDialog}</Typography>
                  </Box>
                  <Field
                    as={Radio}
                    disabled
                    disableTouchRipple
                    checked={values.typeId === 2}
                    icon={<ApartmentIcon sx={styles.iconButton} />}
                    checkedIcon={<ApartmentIcon sx={styles.iconButtonSelected} />}
                  />
                </CardActionArea>
              </Card>
            </ListItem>
            <ListItem>
              <Card sx={values.typeId === 1 ? styles.selectedSiteTypeCard : styles.siteTypeCard}>
                <CardActionArea sx={styles.typeSelection} disableRipple onClick={handleSiteTypeSelection(1)}>
                  <Box>
                    <Typography variant="body1">{commercial}</Typography>
                    <Typography variant="body2">{commercialDialog}</Typography>
                  </Box>
                  <Field
                    as={Radio}
                    disabled
                    disableTouchRipple
                    checked={values.typeId === 1}
                    icon={<BusinessIcon sx={styles.iconButton} />}
                    checkedIcon={<BusinessIcon sx={styles.iconButtonSelected} />}
                  />
                </CardActionArea>
              </Card>
            </ListItem>
          </List>
        </RadioGroup>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Checkbox
            name="newProperty"
            value="check"
            checked={!values.newProperty}
            onChange={() => {
              const newPropertyValue = !values.newProperty;
              setFieldValue('newProperty', newPropertyValue);
              setTimeout(() => {
                if (newPropertyValue) {
                  setFieldValue('newPropertyId', '');
                  setFieldValue('systemId', '');
                  setFieldValue('systemPassword', '');
                }
              }, 0); // Timeout delay is set to 0 to ensure it runs after the synchronous state update.
            }}
          />
          {!values.newProperty ? 'My IXGW-GW is connected to an existing IXG Site.' : 'Existing IXG Property'}
        </Grid>
        <Grid item xs={12} hidden={values.newProperty}>
          <Grid item xs={4} padding={1}>
            <FormControl
              sx={styles.inputField}
              size="small"
              error={touched.newPropertyId && Boolean(errors.newPropertyId)}
            >
              <InputLabel id="ixg-property-id-label">IXG Property ID</InputLabel>
              <Field
                name="newPropertyId"
                id="newPropertyId"
                as={Select}
                labelId="ixg-property-id-label"
                label="IXG Property ID"
                disabled={values.newProperty}
              >
                {aclSites &&
                  aclSites.map((id) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={4} padding={1}>
            <Field
              name="systemId"
              as={TextField}
              label="System ID"
              fullWidth
              size="small"
              variant="outlined"
              disabled={values.newProperty}
              helperText={touched.systemId && errors.systemId}
              error={touched.systemId && !!errors.systemId}
            />
          </Grid>
          <Grid item xs={4} padding={1}>
            <Field
              name="systemPassword"
              as={TextField}
              label="System Password"
              fullWidth
              size="small"
              variant="outlined"
              disabled={values.newProperty}
              helperText={touched.systemPassword && errors.systemPassword}
              error={touched.systemPassword && !!errors.systemPassword}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const Confirmation: React.FC<SiteInformationProps> = ({ formikProps }) => {
  //TODO: New strings @gunner

  if (formikProps.values.typeId === 1) {
    return (
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Box>
          <Typography variant="h5">Commercial System Overview</Typography>
        </Box>
        <Box>
          <List>
            <ListItem>
              Door stations will be programmed to call all answering stations and AIPHONE IXG mobile apps.
            </ListItem>
            <ListItem>
              Answering stations and AIPHONE IXG mobile apps can call, monitor, and unlock any door stations.
            </ListItem>
            <ListItem>
              Once the setup wizard is completed, you can modify the settings of devices and add more AIPHONE IXG mobile
              app groups.
            </ListItem>
          </List>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h5">Next steps in the commercial setup wizard:</Typography>
        </Box>
        <Box>
          <List>
            <ListItem>Register Gateway</ListItem>
            <ListItem>Discover and Add Devices</ListItem>
            <ListItem>Add a Mobile App Group</ListItem>
            <ListItem>Customize Devices</ListItem>
            <ListItem>Configure Network Settings</ListItem>
          </List>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Box>
          <Typography variant="h5">Multi-Tenant System Overview</Typography>
        </Box>
        <Box
          sx={{
            padding: 1
          }}
        >
          <List>
            <ListItem>
              Call destinations and address books will be automatically configured for devices and apps based on Unit
              membership.
            </ListItem>
            <ListItem>
              Entrance and Guard Units can communicate with all other Units. Entrance and Guard Units will be created
              automatically if you have these stations:
              <br /> - IXG-MK (Guard Station) <br />- IXG-DM7-* (Entrance Station)
            </ListItem>
            <ListItem>
              Devices or apps in a Residential or Commercial Unit can communicate with other devices within the same
              Unit and also Entrance and Guard units.
            </ListItem>
            <ListItem>
              The initial setup process may take up to 15 minutes. Once completed, you can modify the settings of your
              units and intercoms as needed.
            </ListItem>
            <ListItem>
              Once the setup wizard is completed, you can add additional Units and modify device and app settings as
              needed.
            </ListItem>
          </List>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h5">Next steps in the multi-tenant setup wizard:</Typography>
        </Box>
        <Box>
          <List>
            <ListItem>Register Gateway</ListItem>
            <ListItem>Discover and Add Devices</ListItem>
            <ListItem>Create Units</ListItem>
            <ListItem>Assign Devices to Units</ListItem>
            <ListItem>Name Devices</ListItem>
            <ListItem>Configure Network Settings</ListItem>
          </List>
        </Box>
      </Box>
    );
  }
};

const NewSiteDialog = ({ isOpen, setIsOpen }: INewSiteDialog) => {
  const buttonBack = getString('Button_Back');
  const buttonCreateSite = getString('Button_Create_Site');
  const buttonCreateSiteBack = getString('Button_Create_Site_Back');
  const buttonNext = getString('Button_Next');
  const [activeStep, setActiveStep] = React.useState(Steps.SiteInformation);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [aclUserPropertyIDList, setAclUserPropertyIDList] = React.useState<string[]>([]);
  const [createSite, { isLoading: isCreating }] = useCreateSiteMutation();
  const [getUser] = useLazyGetUserWithPublicIdQuery();
  const branchData = useSelector((state: RootState) => state.branches.currentBranch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { aclToken, aclUserName } = useAclAuth();

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = await GetUserInfo(aclUserName, aclToken);
      setAclUserPropertyIDList(userInfo.Data.PropertyIDList ?? []);
    };
    if (aclToken && aclUserName) {
      fetchData();
    }
  }, [aclToken, aclUserName]);

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step: number) => () => setActiveStep(step);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleClose = () => {
    setIsOpen(false);
    handleReset();
  };

  const prepareGateway = async () => {
    setIsLoading(true);
    dispatch(resetGatewayState());
    try {
      const aclSiteId = await fetchAclSiteId();
      dispatch(updateGWId(aclSiteId));
      return aclSiteId;
    } catch (error) {
      console.error('An error occurred:', error);
      setErrorMessage('Error fetching site ID. Please try again');
      setIsLoading(false);
      return;
    }
  };

  const validationSchema = Yup.object().shape({
    siteName: Yup.string().required(t('Required')).max(100, t('Must_be_100_characters_or_less')),
    siteAddress: Yup.string().required(t('Required')).max(100, t('Must_be_100_characters_or_less')),
    siteCity: Yup.string().required(t('Required')).max(100, t('Must_be_100_characters_or_less')),
    stateId: Yup.string().required(t('Required')),
    countryId: Yup.string().required(t('Required')),
    siteZip: Yup.string().required(t('Required')).max(10, t('Must_be_10_characters_or_less')),
    sitePhoneNumber: Yup.string()
      .required(t('Validations.Phone_Number_Required'))
      .matches(/^(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/, t('Invalid_phone_number')),
    typeId: Yup.number()
      .required(t('Required'))
      .test('is-selected', t('Required'), (value) => value !== 0),
    newPropertyId: Yup.string().when('newProperty', {
      is: false,
      then: (schema) => schema.required(t('IXG_Property_ID_is_required')),
      otherwise: (schema) => schema.notRequired()
    }),
    systemId: Yup.string().when('newProperty', {
      is: false,
      then: (schema) =>
        schema
          .required(t('System_ID_is_required'))
          .matches(/^[a-zA-Z0-9]{1,32}$/, t('System_ID_can_only_contain_up_to_32_alphanumeric_characters')),
      otherwise: (schema) => schema.notRequired()
    }),
    systemPassword: Yup.string().when('newProperty', {
      is: false,
      then: (schema) =>
        schema
          .required(t('System_Password_is_required'))
          .matches(/^[a-zA-Z0-9]{1,32}$/, t('System_Password_can_only_contain_up_to_32_alphanumeric_characters')),
      otherwise: (schema) => schema.notRequired()
    })
  });

  const handleSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<any>) => {
    const awsPropertyId = values.newProperty ? await prepareGateway() : values.newPropertyId;
    const systemId = values.newProperty ? null : values.systemId;
    const systemPassword = values.newProperty ? null : values.systemPassword;

    const createSitePayload = {
      siteData: {
        siteName: values.siteName,
        siteAddress: values.siteAddress || '',
        siteCity: values.siteCity || '',
        stateId: values.stateId || branchData?.stateId,
        siteZip: values.siteZip || '',
        timezone: values.timezone || 7,
        sitePhoneNumber: values.sitePhoneNumber || '',
        multiBuilding: true,
        awsPropertyId: awsPropertyId,
        systemId: systemId,
        systemPassword: systemPassword,
        statusId: 1,
        branchPublicId: branchData?.publicId,
        typeId: values.typeId
      }
    };

    dispatch(updateSite(createSitePayload));
    createSite(createSitePayload)
      .then((result) => {
        setSubmitting(false);
        setIsLoading(false);
        if ('error' in result) {
          throw new Error(t('Unexected_Error'));
        } else if (result.data) {
          const userId = localStorage.getItem('userId');
          if (userId) {
            getUser(userId).then(({ data }) => {
              if (data) {
                dispatch(setCurrentUser(data));
              }
            });
          }
          navigate(`/site/${result.data}/wizard`);
        } else {
          throw new Error(t('Site_Error_ID_Not_Found'));
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  const renderStep = (step: Steps, formikProps: FormikProps<IFormValues>) => {
    const siteCreated = getString('Site_Created');

    switch (step) {
      case Steps.SiteInformation:
        return <SiteInformation formikProps={formikProps} />;
      case Steps.SiteType:
        return <SiteType formikProps={formikProps} aclSites={aclUserPropertyIDList} />;
      case Steps.Confirmation:
        return <Confirmation formikProps={formikProps} />;
      default:
        return <Box>{siteCreated}</Box>;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <Box sx={styles.newSiteDialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={11}></Grid>
            <Grid item xs={1}>
              <IconButton color="default" aria-label="cancel" onClick={() => handleClose()}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {(formikProps) => {
              const isContinueDisabled = () => {
                const step1UnexpectedErrorKeys = [
                  'siteName',
                  'siteAddress',
                  'countryId',
                  'stateId',
                  'siteCity',
                  'siteZip',
                  'sitePhoneNumber'
                ];
                const actualErrorKeys = Object.keys(formikProps.errors);

                switch (activeStep) {
                  case Steps.SiteInformation: {
                    return actualErrorKeys.some((key) => step1UnexpectedErrorKeys.includes(key));
                  }
                  case Steps.SiteType: {
                    const step2UnexpectedErrorsKeys = [...step1UnexpectedErrorKeys, 'typeId'];
                    if (formikProps.values.newProperty) {
                      return actualErrorKeys.some((key) => step2UnexpectedErrorsKeys.includes(key));
                    }
                    return actualErrorKeys.some((key) =>
                      [...step2UnexpectedErrorsKeys, 'newPropertyId', 'systemId', 'systemPassword'].includes(key)
                    );
                  }
                  default:
                    return formikProps.isValid;
                }
              };

              return (
                <Form>
                  <DialogContent>
                    <Box>
                      <Stepper activeStep={activeStep} nonLinear>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)} disabled>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                    <Box sx={styles.stepContainer}>{renderStep(activeStep, formikProps)}</Box>
                  </DialogContent>
                  <DialogActions>
                    {activeStep === Steps.Confirmation ? (
                      <React.Fragment>
                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                          {buttonBack}
                        </Button>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          type="button"
                          loading={isLoading || isCreating}
                          onClick={() => {
                            if (formikProps.isValid) formikProps.handleSubmit();
                          }}
                        >
                          {buttonCreateSite}
                        </LoadingButton>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                          {buttonCreateSiteBack}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          disabled={isContinueDisabled()}
                          onClick={handleComplete}
                        >
                          {buttonNext}
                        </Button>
                      </React.Fragment>
                    )}
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
      />
    </>
  );
};

const styles = {
  newSiteDialogContainer: {
    width: '100%',
    height: '100%'
  },
  stepContainer: {
    padding: '16px'
  },
  mb2: {
    marginBottom: '16px'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    margin: 0
  }
};

export default NewSiteDialog;
