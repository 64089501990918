import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Typography, Box } from '@mui/material';

interface ErrorAlertProps {
  errorMessage: string;
  onRetry?: () => void;
  customMessage?: string | null;
}

const ErrorAlert = ({ errorMessage, onRetry, customMessage = null }: ErrorAlertProps) => {
  const { t } = useTranslation('licenseManagement');

  useEffect(() => {
    const toastId = 'error-alert-toast';

    if (!toast.isActive(toastId)) {
      toast.error(
        <div style={styles.toastContainer}>
          <Box sx={styles.textContainer}>
            <Typography variant="h6" sx={styles.heading}>
              {errorMessage}
            </Typography>
            <Typography variant="body2" sx={styles.subheading}>
              {customMessage ? customMessage : t('Please_Try_Again')}
            </Typography>
          </Box>
          {onRetry && (
            <Button onClick={onRetry} sx={styles.retryButton}>
              {t('Try_Again')}
            </Button>
          )}
        </div>,
        {
          toastId,
          autoClose: false,
          closeButton: true,
          style: styles.toastStyle
        }
      );
    }
  }, [errorMessage, onRetry, customMessage, t]);

  return null;
};

export default ErrorAlert;

const styles = {
  toastContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textContainer: {
    flexGrow: 1
  },
  heading: {
    color: '#721c24',
    fontWeight: 500,
    fontSize: '15px'
  },
  subheading: {
    color: '#721c24',
    fontSize: '13px'
  },
  retryButton: {
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '12px',
    color: '#721c24',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(114, 28, 36, 0.1)'
    }
  },
  toastStyle: {
    backgroundColor: '#f8d7da',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  }
};
