import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Button,
  Box,
  Typography,
  Chip,
  IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CancelIcon from '@mui/icons-material/Cancel';

// #region import LicenseManagement
// Hooks
import { useUserAccessibleAiphoneSites } from 'features/LicenseManagement/hooks/useUserAccessibleAiphoneSites';
// Shared Components
import { CreateDeploymentRequest, DeploymentType } from 'features/LicenseManagement/components/common/Types';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { IStateValue, ICountryValue } from 'shared/utils/EnumUtils';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
// #endregion

// #region Interfaces
interface CreateDeploymentTypeModalProps {
  /**
   * Indicates whether the modal is open.
   * This modal is for entering the deployment type.
   */
  open: boolean;
  /**
   * Callback to open the next modal (information modal).
   */
  onOpenInformationModal: () => void;
  /**
   * Callback to close the modal.
   * @param resetForm Whether to reset the form or not.
   */
  onClose: (resetForm: boolean) => void;
  /**
   * Form data entered by the user.
   */
  formData: CreateDeploymentRequest;
  /**
   * Function to update the form data.
   */
  setFormData: React.Dispatch<React.SetStateAction<CreateDeploymentRequest>>;
}
// #endregion
/**
 * Props interface for CreateDeploymentTypeModal
 */
const CreateDeploymentTypeModal = (props: CreateDeploymentTypeModalProps) => {
  const { open, onOpenInformationModal, onClose, formData, setFormData } = props;
  const { currentBranch } = useReduxBranches();
  const { currentUser } = useReduxUsers();
  // #region Translation
  const { t } = useTranslation('licenseManagement');
  // #endregion

  // #region load and filter site information
  const filteredMySites = useUserAccessibleAiphoneSites(currentBranch, currentUser);

  const sitesOptions = Object.keys(filteredMySites).map((site) => ({
    value: site,
    label: filteredMySites[site].siteName
  }));
  // #endregion

  // #region Handlers
  const handleContinue = () => {
    onClose(false);
    onOpenInformationModal();
  };

  const handleDeploymentTypeChange = (event: SelectChangeEvent) => {
    const value = Number(event.target.value);
    setFormData((prevData: CreateDeploymentRequest) => ({
      ...prevData,
      deployment: {
        ...prevData.deployment,
        type: value as DeploymentType
      }
    }));
  };

  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const handleSiteChange = (event: SelectChangeEvent<string | null>) => {
    const { value } = event.target;
    const selectedSiteId = value;
    let address = formData.address;
    if (selectedSiteId) {
      const site = filteredMySites[selectedSiteId];
      const state = enumList.state[site.stateId] as IStateValue;
      const country = enumList.country[state?.countryId] as ICountryValue;
      address = {
        ...formData.address,
        street: site.siteAddress,
        city: site.siteCity,
        postalCode: site.siteZip,
        aptSuiteBuilding: site.siteAddress2,
        stateName: state.value,
        countryName: country.value
      };
    } else {
      address = {
        ...formData.address,
        street: '',
        city: '',
        postalCode: '',
        aptSuiteBuilding: ''
      };
    }

    setFormData((prevData) => ({
      ...prevData,
      linkedAiphoneSitePublicId: selectedSiteId ?? null,
      address: address
    }));
  };

  const getRadioStyle = (type: DeploymentType) => ({
    color: formData.deployment.type === type ? styles.themeColorTertiary.color : 'inherit'
  });
  // #endregion

  // #region Render
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(true)}
        PaperProps={{
          style: styles.modalContainer
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography style={styles.moduleTitle}>{t('Deployment_Details_Title')}</Typography>
          <IconButton
            color="default"
            aria-label="cancel"
            onClick={() => onClose(false)}
            style={{ marginRight: '1rem' }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Typography style={styles.moduleSecondaryTitle}>{t('Deployment_Type_Subheader')}</Typography>
        <Box style={styles.divider} />
        <DialogContent style={styles.moduleContent}>
          <Box>
            <Typography style={styles.moduleSubTitle}>{t('Choose_Deployment_Type')}</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={formData.deployment.type}
                onChange={handleDeploymentTypeChange}
                style={{ marginLeft: '1.5rem' }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    value={DeploymentType.Cloud}
                    control={<Radio style={getRadioStyle(DeploymentType.Cloud)} />}
                    label={
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <CloudUploadIcon
                          style={{
                            ...styles.iconMarginRight,
                            color: getRadioStyle(DeploymentType.Cloud).color
                          }}
                        />
                        <Typography>Cloud</Typography>
                      </Box>
                    }
                  />
                </Box>
                <Typography variant="body2" color="textSecondary" style={{ marginLeft: '1.5rem' }}>
                  {t('Deployment_Type_Cloud_Description')}
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    value={DeploymentType.Local}
                    control={<Radio style={getRadioStyle(DeploymentType.Local)} />}
                    label={
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <LocationOnIcon
                          style={{
                            ...styles.iconMarginRight,
                            color: getRadioStyle(DeploymentType.Local).color
                          }}
                        />
                        <Typography>Local</Typography>
                      </Box>
                    }
                  />
                </Box>
                <Typography variant="body2" color="textSecondary" style={{ marginLeft: '1.5rem' }}>
                  {t('Deployment_Type_Local_Description')}
                </Typography>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box mb={3} style={{ marginTop: '1.5rem', height: '100%' }}>
            <Typography component="legend" style={styles.moduleSubTitle}>
              {t('Deployment_Site_Title')}
            </Typography>
            <Typography style={{ marginLeft: '0.2rem' }}>{t('Deployment_Site_Description')}</Typography>
            <FormControl style={{ marginTop: '1.0rem' }}>
              <InputLabel id="site-select-label">Select site</InputLabel>
              <Select
                value={formData.linkedAiphoneSitePublicId ?? ''}
                sx={styles.select}
                labelId="site-select-label"
                onChange={handleSiteChange}
                renderValue={(selected) => (
                  <Box sx={styles.chipBox}>
                    <Chip
                      key={selected}
                      label={
                        selected !== null ? filteredMySites[selected].siteName : t('Deployment_Site_Not_Connected')
                      }
                    />
                  </Box>
                )}
                label="Select site"
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: '0.2rem',
                      maxHeight: 200
                    }
                  }
                }}
              >
                <MenuItem>{t('Deployment_Site_Not_Connected')}</MenuItem>
                {sitesOptions.map((site) => (
                  <MenuItem key={site.value} value={site.value}>
                    {site.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box style={styles.actionContainer}>
            <Button variant="contained" sx={styles.continueButton} onClick={handleContinue}>
              {t('Continue')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
  // #endregion
};

// #region Styles
const styles = {
  modalContainer: {
    width: '60%',
    minWidth: '750px',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  moduleContent: {
    padding: '1.5rem'
  },
  moduleTitle: {
    fontSize: '1.5rem',
    margin: '0.8rem 0rem 0rem 1.5rem',
    fontWeight: 'bold'
  },
  moduleSecondaryTitle: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0rem 0rem 0.3rem 1.5rem'
  },
  divider: {
    height: '1px',
    backgroundColor: '#e0e0e0',
    margin: '0 0.5rem'
  },
  moduleSubTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#444'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1rem'
  },
  select: {
    height: '100%',
    width: '300px',
    '& .MuiInputBase-root': {
      height: '100%',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    }
  },
  backButton: {
    fontWeight: 600,
    textTransform: 'none',
    color: '#003366'
  },
  continueButton: {
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#003366',
    color: '#fff'
  },
  iconMarginRight: {
    marginRight: '0.5rem'
  },
  themeColorTertiary: {
    color: '#1976d2'
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    maxHeight: '100%',
    overflowY: 'auto'
  }
};
// #endregion
export default CreateDeploymentTypeModal;
