import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { InvoiceDetailResponse } from '../components/common/Types';
import { fetchInvoicesByDeploymentId } from '../lib/api/LicenseManagementApi';

export const useInvoicesByDeploymentId = (
  aiphoneBranchPublicId: string,
  deploymentId: string
): UseQueryResult<InvoiceDetailResponse[]> => {
  return useQuery<InvoiceDetailResponse[]>({
    queryKey: ['invoices', deploymentId],
    queryFn: () => fetchInvoicesByDeploymentId(aiphoneBranchPublicId, deploymentId),
    staleTime: 60000,
    enabled: !!deploymentId
  });
};
