/**
 * External resource container separated by deplyment stage (account)
 */
interface CloudConfiguration {
  /**
   * AWS Cognito User Pool ID
   */
  cognitoUserPool: string;

  /**
   * AWS Cognito Client ID belonging to the above User Pool
   */
  cognitoClientId: string;

  /**
   * Aiphone Cloud API
   */
  apiEndpoint: string;

  /**
   * Aiphone Cloud Open API
   */
  openApiEndpoint: string;

  /**
   * Apione Http Cloud API
   */
  httpApiEndpoint: string;

  /**
   * IXG API endpoint
   */

  ixgApiEndPoint: string;
  ixgApiEndPoint2: string;

  /**
   * IXG AWS Cognito User Pool ID
   */
  ixgCognitoUserPool: string;

  /**
   * IXG AWS Cognito Client ID belonging to the above User Pool
   */
  ixgCognitoClientId: string;

  /**
   * IXG s3 End point to upload site data
   */
  ixgs3EndPoint: string;

  /**
   *  license Api endpoint for license management
   */
  licenseApiEndpoint: string;
}

/**
 * Configuration for CDG Dev 2 Resources
 */
const dev2: CloudConfiguration = {
  cognitoUserPool: 'us-east-1_keSbwCMY7',
  cognitoClientId: '6ui5if1gr6d6ammg0ukun5tkbn',
  apiEndpoint: 'https://api.alpha.aiphone.cloud',
  openApiEndpoint: 'https://openapi.alpha.aiphone.cloud',
  httpApiEndpoint: 'https://4nrkqqpaec.execute-api.us-east-1.amazonaws.com',
  ixgApiEndPoint: 'https://8b1t0nj0bb.execute-api.ap-northeast-1.amazonaws.com/dev',
  ixgApiEndPoint2: 'https://xryzajak33.execute-api.us-east-1.amazonaws.com/dev',
  ixgCognitoUserPool: 'ap-northeast-1_AxmqwjXY2',
  ixgCognitoClientId: '2up4adakkc1qupqda54a1h9a45',
  ixgs3EndPoint: 'https://ixg-manage-upload-1.s3.ap-northeast-1.amazonaws.com',
  licenseApiEndpoint: 'https://license-api.alpha.aiphone.cloud'
};

/**
 * Configuration for CDG Dev 1 Resources
 */
const dev1: CloudConfiguration = {
  cognitoUserPool: 'us-east-1_7Ha3gMZyE',
  cognitoClientId: '6d7vvvfpiid2tnjphcf6g2gba7',
  apiEndpoint: 'https://api.beta.aiphone.cloud',
  openApiEndpoint: 'https://openapi.beta.aiphone.cloud',
  httpApiEndpoint: 'https://wts0w1jkm8.execute-api.us-east-1.amazonaws.com',
  ixgApiEndPoint: 'https://8b1t0nj0bb.execute-api.ap-northeast-1.amazonaws.com/dev',
  ixgApiEndPoint2: 'https://xryzajak33.execute-api.us-east-1.amazonaws.com/dev',
  ixgCognitoUserPool: 'ap-northeast-1_AxmqwjXY2',
  ixgCognitoClientId: '2up4adakkc1qupqda54a1h9a45',
  ixgs3EndPoint: 'https://ixg-manage-upload-1.s3.ap-northeast-1.amazonaws.com',
  licenseApiEndpoint: 'https://license-api.beta.aiphone.cloud'
};

/**
 * Configuration for CDG Staging Resources
 */
const staging: CloudConfiguration = {
  cognitoUserPool: 'us-east-1_EEea8h5d2',
  cognitoClientId: '6kqjdc59pj339nn5s9hvug4pga',
  apiEndpoint: 'https://api.preprod.aiphone.cloud',
  openApiEndpoint: 'https://openapi.preprod.aiphone.cloud',
  httpApiEndpoint: 'https://1gdphiasoh.execute-api.us-east-1.amazonaws.com',
  ixgApiEndPoint: 'https://4mw1mwj51d.execute-api.ap-northeast-1.amazonaws.com/dev',
  ixgApiEndPoint2: 'https://vp492i6ma3.execute-api.us-east-1.amazonaws.com/dev',
  ixgCognitoUserPool: 'ap-northeast-1_fD3xBcZ3U',
  ixgCognitoClientId: '6e8gog2tbk14panio4onetm0dc',
  ixgs3EndPoint: 'https://ixg-manage-upload-3.s3.ap-northeast-1.amazonaws.com',
  licenseApiEndpoint: 'https://license-api.preprod.aiphone.cloud'
};

/**
 * Configuration for CDG Production Resources
 */
const prod: CloudConfiguration = {
  cognitoUserPool: 'us-east-1_OQcoDfhoU',
  cognitoClientId: '5k0uiivgommneuehtdqamqvnr',
  apiEndpoint: 'https://api.prod.aiphone.cloud',
  openApiEndpoint: 'https://openapi.prod.aiphone.cloud',
  httpApiEndpoint: 'https://mjoypgoldj.execute-api.us-east-1.amazonaws.com',
  ixgApiEndPoint: 'https://4mw1mwj51d.execute-api.ap-northeast-1.amazonaws.com/dev',
  ixgApiEndPoint2: 'https://vp492i6ma3.execute-api.us-east-1.amazonaws.com/dev',
  ixgCognitoUserPool: 'ap-northeast-1_fD3xBcZ3U',
  ixgCognitoClientId: '6e8gog2tbk14panio4onetm0dc',
  ixgs3EndPoint: 'https://ixg-manage-upload-3.s3.ap-northeast-1.amazonaws.com',
  licenseApiEndpoint: 'https://license-api.prod.aiphone.cloud'
};

/**
 * Applies specific configurations depending on the stage
 *
 * @remarks Environment variables are exporeted from CICD pipeline as json file.
 * For local testing, the above pre-defined variables can be used.
 * See the aiphone-cloud-cicd-pipeline for matching configuration
 */
let cloudConfig: CloudConfiguration = dev1;
if (process.env.REACT_APP_CONFIG != null && process.env.REACT_APP_CONFIG != '') {
  try {
    cloudConfig = JSON.parse(process.env.REACT_APP_CONFIG);
  } catch {
    console.error('config.json read error');
  }
} else {
  switch (process.env.REACT_APP_STAGE) {
    case 'devtest':
      cloudConfig = dev2;
      break;
    case 'dev':
      cloudConfig = dev1;
      break;
    case 'staging':
      cloudConfig = staging;
      break;
    case 'prod':
      cloudConfig = prod;
      break;
    default:
      cloudConfig = dev2;
      break;
  }
}

export default {
  ...cloudConfig
};

//auth consts for login and registration
//values are imported from CICD pipeline
export const USER_POOL_ID = cloudConfig.cognitoUserPool;
export const CLIENT_ID = cloudConfig.cognitoClientId;

export const POOL_DATA = {
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID
};
