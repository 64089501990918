import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllUsers, getUserById } from 'shared/api/Aws/awsApi';
import { ErrorType } from '../../shared/api/ApiError';
import { RootState } from 'store';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

export type CloudUserResponse = {
  c2_company_id: string;
  c2_user_id: string | null;
  cloud_organization_public_id: string;
  created_on: string;
  email: string;
  first_name: string;
  id: number;
  is_deleted: boolean;
  language: string | null;
  last_name: string;
  last_updated_on: string;
  marketing_opt_in: boolean;
  phone_extension: string | null;
  phone_number: string;
  public_id: string;
  role: string | null;
  title: string | null;
  country_id: number;
  language_id: number | null;
  mfa_enabled: boolean | null;
  mfa_type_id: number | null;
  stripe_customer_id: string | null;
  permissions: IUserPermissions;
};

export type CloudUser = {
  publicId: string;
  countryId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string | null;
  c2UserId: string | null;
  createdOn: string;
  lastUpdatedOn: string;
  marketingOptIn: boolean;
  title: string | null;
  languageId: number | null;
  phoneExtension: string | null;
  permissions: IUserPermissions;
  mfaEnabled: boolean | null;
  mfaTypeId: number | null;
  stripeCustomerId: string | null;
};

export type CloudUserBillingInformation = {
  id: number;
  cloudUserPublicId: string;
  stripeCustomerId: string;
  createdOn: string;
  lastUpdatedOn: string;
};

export interface IRoleBase {
  publicId: string;
  roleName: string;
}

export enum IPermissionEnum {
  ALLOW = 'Allow',
  PREVENT = 'Prevent',
  PROHIBIT = 'Prohibit'
}

export interface ICapability {
  name: string;
  permission: IPermissionEnum;
}

export interface IRole extends IRoleBase {
  capabilityList: ICapability[];
}

export interface ICompanyRole extends IRoleBase {
  companyTypeContextId: number;
}

export interface IPermission {
  roleList: IRole[];
}

export interface ISitePermissions {
  [key: string]: IPermission;
}

export interface IBranchPermission extends IPermission {
  companyName: string;
  branchName: string;
}

export interface IBranchPermissions {
  [key: string]: IBranchPermission;
}

export interface IUserPermissions {
  site: ISitePermissions;
  branch: IBranchPermissions;
  global: IGlobalRoleList;
}

export interface IAvailableRoles {
  site: ICompanyRole[];
  branch: ICompanyRole[];
  global?: IRoleBase[];
}
export interface IGlobalRoleList {
  roleList: IGlobalRole[];
}
interface IGlobalRole {
  capabilityList: ICapability[];
  publicId: string;
  roleName: string;
}
interface UsersState {
  users: CloudUser[];
  currentUser: CloudUser | null;
  usersError: string | null;
  loading: boolean;
  availableRoles: IAvailableRoles | null;
}

export const initialState: UsersState = {
  users: [],
  currentUser: null,
  usersError: null,
  loading: false,
  availableRoles: null
};

export enum ContextType {
  GLOBAL = 1,
  BRANCH = 2,
  SITE = 3,
  DEALER = 4,
  PROPERTY_MANAGER = 5
}

/**Fetch all cloud users*/
export const fetchUsers = createAsyncThunk<CloudUserResponse[], void, { rejectValue: { errorMessage: ErrorType } }>(
  'users/fetchUsers',
  async (_, { rejectWithValue }) => {
    const response = await getAllUsers();
    if (!response?.data) {
      return rejectWithValue({ errorMessage: ErrorType.SERVER });
    }
    return response.data;
  }
);

/**Fetch cloud user by user id*/
export const fetchUserById = createAsyncThunk<
  CloudUser,
  { userSub: string },
  { rejectValue: { errorMessage: ErrorType } }
>('users/fetchUserById', async (params, { rejectWithValue }) => {
  const response = await getUserById(params);
  if (!response?.data) {
    return rejectWithValue({ errorMessage: ErrorType.SERVER });
  }
  return response.data;
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUsers: (state, action) => {
      const incomingUser = action.payload;
      incomingUser.forEach((iUser: CloudUser) => {
        const index = state.users.findIndex((user: CloudUser) => user.publicId === iUser.publicId);
        if (index !== -1) {
          state.users[index] = iUser;
        } else {
          state.users.push(iUser);
        }
      });
    },
    resetUsersState: (state) => {
      state.users = [];
      state.currentUser = null;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setAssignableRoles: (state, action) => {
      const rolesData = action.payload;

      const site = rolesData.site
        ? rolesData.site.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      const branch = rolesData.branch
        ? rolesData.branch.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      const global = rolesData.global
        ? rolesData.global.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      state.availableRoles = { site, branch, global };
    },
    setUserProfile: (state, action) => {
      if (state.currentUser) {
        state.currentUser.firstName = action.payload.firstName;
        state.currentUser.lastName = action.payload.lastName;
        state.currentUser.phoneNumber = action.payload.phoneNumber;
        state.currentUser.marketingOptIn = action.payload.marketingOptIn;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const updatedUserStructure = action.payload.map((user) => ({
          c2UserId: user.c2_user_id,
          cloudOrganizationPublicId: user.cloud_organization_public_id,
          createdOn: user.created_on,
          email: user.email,
          firstName: user.first_name,
          language: user.language,
          lastName: user.last_name,
          lastUpdatedOn: user.last_updated_on,
          marketingOptIn: user.marketing_opt_in,
          phoneExtension: user.phone_extension,
          phoneNumber: user.phone_number,
          publicId: user.public_id,
          role: user.role,
          title: user.title,
          countryId: user.country_id,
          languageId: user.language_id,
          mfaEnabled: user.mfa_enabled,
          mfaTypeId: user.mfa_type_id,
          stripeCustomerId: user.stripe_customer_id,
          permissions: user.permissions || null // Ensure this field is populated correctly
        }));

        state.users = updatedUserStructure;
        state.loading = false;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
        state.usersError = 'Error during fetching users.';
      })
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.loading = false;
        state.usersError = 'Error during fetching user by id.';
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUserWithPublicId.matchFulfilled, (state, action) => {
        const cloudUser: CloudUser = action.payload;
        state.currentUser = cloudUser;
        sessionStorage.setItem('user', JSON.stringify(cloudUser));
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUserBillingInformation.matchFulfilled, (state, action) => {
        const cloudUserBillingInformation: CloudUserBillingInformation | null = action.payload;

        if (state.currentUser) {
          state.currentUser.stripeCustomerId = cloudUserBillingInformation?.stripeCustomerId || '';
        }
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUserBillingInformation.matchRejected, (state) => {
        if (state.currentUser) {
          state.currentUser.stripeCustomerId = '';
        }
      })
      .addMatcher(aiphoneCloudApi.endpoints.getAssignableRoles.matchFulfilled, (state, action) => {
        const rolesData = action.payload;
        /* This logic might not be correct.
          The roleData was causing issues in Branch Users.
        */
        if (!rolesData) {
          return;
        }
        const site = rolesData.site
          ? rolesData?.site?.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        const branch = rolesData.branch
          ? rolesData.branch.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        const global = rolesData.global
          ? rolesData.global.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        state.availableRoles = { site, branch, global };
      });
  }
});

export const getCurrentUser = (state: RootState) => state.users.currentUser;
export const getBranchList = createSelector(
  (state: RootState) => state.users.currentUser,
  (currentUser) => {
    if (currentUser?.permissions.branch) {
      const branches = Object.entries(currentUser.permissions.branch).map(([key, value]) => ({
        branchId: key,
        branchName: `${value.branchName}`
      }));
      return branches;
    }
    return undefined;
  }
);

export const { addUsers, resetUsersState, setCurrentUser, setAssignableRoles, setUserProfile } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
