import {
  Box,
  Card,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { DeploymentDoorSize, Product } from 'features/LicenseManagement/components/common/Types';
import { productDescriptions } from './ProductDescription';

interface PlanSelectorProps {
  plans: Product[];
  selectedPlan: string;
  onPlanSelect: (id: string) => void;
  planType: DeploymentDoorSize;
  setPlanType: React.Dispatch<React.SetStateAction<DeploymentDoorSize>>;
}

// Function to format the currency symbol
const formatCurrency = (currencyCode: string, amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode
  }).format(amount);
};

export const PlanSelector: React.FC<PlanSelectorProps> = ({
  plans,
  selectedPlan,
  onPlanSelect,
  planType,
  setPlanType
}) => {
  const { t } = useTranslation('licenseManagement');

  return (
    <Box className="plan-selector">
      <ToggleButtonGroup value={planType} exclusive onChange={(_, value) => setPlanType(value)}>
        {['Basic', 'Growing', 'Enterprise'].map((type) => (
          <ToggleButton key={type} value={type}>
            {t(`${type}_Plan`)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Typography variant="subtitle2" sx={styles.planSelector}>
        <span style={styles.text}>{t('Save_On_5year_Plan')}</span>
        <span style={styles.plan_text}>{t('5year_Plan')}</span>
      </Typography>
      <RadioGroup value={selectedPlan} onChange={(e) => onPlanSelect(e.target.value)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} key={plan.id}>
              <Card
                variant="outlined"
                onClick={() => onPlanSelect(plan.id)}
                sx={{ ...(selectedPlan === plan.id && styles.cardSelected), ...styles.cardContent }}
              >
                <Radio
                  checked={selectedPlan === plan.id}
                  onChange={() => onPlanSelect(plan.id)}
                  value={plan.id}
                  color="primary"
                />
                <CardContent>
                  <Typography variant="h6">{plan.name}</Typography>
                  {productDescriptions[plan.name]?.description
                    .split('.')
                    .filter(Boolean)
                    .map((sentence, index) => (
                      <Box key={index} sx={styles.productContainer}>
                        <Typography variant="body2" sx={styles.iconContainer}>
                          <CheckIcon sx={styles.icon} />
                        </Typography>
                        <Typography variant="body2">{sentence.trim()}.</Typography>
                      </Box>
                    ))}
                  <Box sx={styles.productContainer}>
                    <Typography variant="body2" sx={styles.iconContainer}>
                      <CheckIcon sx={styles.icon} />
                    </Typography>
                    <Typography variant="body2">
                      {plan.duration} {t('Months')}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={styles.currencyText}>
                    {formatCurrency(plan.currency, plan.unitAmount / 100)} per year
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

const styles = {
  cardSelected: {
    borderColor: '#3f51b5'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    padding: 1
  },
  currencyText: {
    marginRight: '0'
  },
  planSelector: {
    marginTop: '10px'
  },
  productContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginTop: 1
  },
  icon: {
    color: '#006400'
  },
  iconContainer: {
    color: 'green',
    fontWeight: 'bold'
  },
  text: {
    color: '#006400'
  },
  plan_text: {
    color: 'textSecondary'
  }
};
