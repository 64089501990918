export const handleExit = () => {
  window.location.href = '/licensemanagement';
};

export const handleContinue = (
  activeStep: number,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  totalSteps: number
) => {
  if (activeStep < totalSteps - 1) {
    setActiveStep((prev) => prev + 1);
  }
};

export const handleBack = (activeStep: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => {
  if (activeStep > 0) {
    setActiveStep((prev) => prev - 1);
  }
};
