import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

// Components
import { UnactivatedLocalLicense } from 'features/LicenseManagement/components/license/LicenseDetail/Unactivated/Local/UnactivatedLocalLicenseDetail';
import { UnactivatedCloudLicense } from 'features/LicenseManagement/components/license/LicenseDetail/Unactivated/Cloud/UnactivatedCloudLicenseDetail';
import { ActivatedLicenseDetail } from 'features/LicenseManagement/components/license/LicenseDetail/Activated/ActivatedLicenseDetail';
// Hooks
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
// Shared Components
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import Header from 'features/LicenseManagement/components/common/Header';
import { PermissionsContextType } from 'permissions/utils';
import { usePermission } from 'context/PermissionContext';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
// Types
import { LicenseStatus, DeploymentType } from 'features/LicenseManagement/components/common/Types';
// #endregion

const LicenseDetails = () => {
  const { t } = useTranslation('licenseManagement');
  const { deploymentId } = useParams();
  const { isAllowedTo } = usePermission();
  const { currentBranch } = useReduxBranches();
  const location = useLocation();

  // Check if the user is renewing the license
  const renewLocalLicense = location.state?.renewLocalLicense || false;

  // Determine if the current navigation is from a Stripe checkout session for cloud deployment payment
  const params = new URLSearchParams(location.search);
  const isFromStripeCheckout = params.has('aiphoneBranchPublicId');

  const { data: deployment, isLoading, error, refetch } = useDeploymentById(deploymentId || '', isFromStripeCheckout);

  const [status, setStatus] = useState(LicenseStatus.Unknown);

  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);

  useEffect(() => {
    if (deployment) {
      setStatus(deployment.licenseStatus);
    }
  }, [deployment]);
  // #endregion

  // #region Conditional Rendering Logic
  if (!currentBranch) {
    return <LoadingSpinner />;
  }

  if (!canViewDeployment) {
    return <Typography>{t('Missing_Permission_To_View_Deployment')}</Typography>;
  }

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  if (error) {
    if (error.message === 'Timeout: Deployment Info not received') {
      return (
        <ErrorAlert
          errorMessage={error.message}
          customMessage={t('Error_Loading_License_Details_Timeout')}
          onRetry={refetch}
        />
      );
    } else {
      return (
        <ErrorAlert errorMessage={error.message} customMessage={t('Error_Loading_License_Details')} onRetry={refetch} />
      );
    }
  }

  if (isLoading || !deployment) {
    return <LoadingSpinner />;
  }

  if (isFromStripeCheckout && deployment?.licenseStatus !== LicenseStatus.Activated) {
    return <LoadingSpinner />;
  }

  if (!deployment) {
    return <Typography>{t('Error_Loading_Data')}</Typography>;
  }
  // #endregion

  // Determine which component to render based on status and deploymentType
  const renderContent = () => {
    if (renewLocalLicense && deployment?.deploymentType === DeploymentType.Local) {
      return <UnactivatedLocalLicense accountNumber={deployment.hartmannAccountNumber} deployment={deployment} />;
    }
    if (status === LicenseStatus.Unactivated) {
      if (deployment?.deploymentType === DeploymentType.Local) {
        return <UnactivatedLocalLicense accountNumber={deployment.hartmannAccountNumber} deployment={deployment} />;
      } else if (deployment.deploymentType === DeploymentType.Cloud) {
        return <UnactivatedCloudLicense deployment={deployment} />;
      }
    } else if (status === LicenseStatus.Activated || status === LicenseStatus.Expired) {
      return <ActivatedLicenseDetail deployment={deployment} />;
    }
    return null;
  };

  return (
    <Box sx={LicenseManagementCommonStyles.pageHeaderContainer}>
      <Header deployment={deployment} status={status} />
      {renderContent()}
    </Box>
  );
};

export default LicenseDetails;
