//TODO -------------------------------------------------------------------
// support for multilingual using libraries such as react-intl or i18next.
import strings from 'features/SimBilling/Types/en.json';
import axios from 'axios';
//------------------------------------------------------------------------
const { errorTypeValues } = strings;

export enum ErrorType {
  TIMEOUT = 'timeout',
  NETWORK = 'network',
  NOT_FOUND = 'not found',
  SERVER = 'server',
  SERVER_DB = 'server_DB',
  UNEXPECTED = 'unexpected',
  // Validates the backend parameters when posting site information obtained from ACL's API.
  // If the site information pertains to an old site (prior to Phase 1A),
  // an error will occur during this validation process.
  // For such sites, SIM Billing will not be enabled, and an error message will be displayed.
  EXCLUDED = 'excluded',
  INCOMPLETE_USER_ACCOUNT = 'incompleteUserAccount'
}

export const ErrorTypeValues = {
  [ErrorType.TIMEOUT]: {
    message1: errorTypeValues.timeout.message1,
    message2: errorTypeValues.timeout.message2,
    message3: errorTypeValues.timeout.message3
  },
  [ErrorType.NETWORK]: {
    message1: errorTypeValues.network.message1,
    message2: errorTypeValues.network.message2,
    message3: errorTypeValues.network.message3
  },
  [ErrorType.NOT_FOUND]: {
    message1: errorTypeValues.notFound.message1,
    message2: errorTypeValues.notFound.message2,
    message3: errorTypeValues.notFound.message3
  },
  [ErrorType.SERVER]: {
    message1: errorTypeValues.server.message1,
    message2: errorTypeValues.server.message2,
    message3: errorTypeValues.server.message3
  },
  [ErrorType.SERVER_DB]: {
    message1: errorTypeValues.serverDb.message1,
    message2: errorTypeValues.serverDb.message2,
    message3: errorTypeValues.serverDb.message3
  },
  [ErrorType.UNEXPECTED]: {
    message1: errorTypeValues.unexpected.message1,
    message2: errorTypeValues.unexpected.message2,
    message3: errorTypeValues.unexpected.message3
  },
  [ErrorType.EXCLUDED]: {
    message1: errorTypeValues.excluded.message1,
    message2: errorTypeValues.excluded.message2,
    message3: errorTypeValues.excluded.message3
  },
  [ErrorType.INCOMPLETE_USER_ACCOUNT]: {
    message1: errorTypeValues.incompleteUserAccount.message1,
    message2: errorTypeValues.incompleteUserAccount.message2,
    message3: errorTypeValues.incompleteUserAccount.message3
  }
};

export class ApiError extends Error {
  constructor(message: string, public type: ErrorType, public code?: string, public session?: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export const classifyAndThrowError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const isDataObject = typeof error.response.data === 'object' && error.response.data !== null;
      const responseData = isDataObject ? error.response.data : JSON.parse(error.response.data || '{}');
      const errorCode = responseData.error?.code || 'Unknown';
      const session = responseData.error?.session || 'Unknown';

      switch (error.response.status) {
        case 404:
          throw new ApiError(
            error.response.data.message || JSON.parse(error.response.data)?.errorDetails || 'Error',
            ErrorType.NOT_FOUND,
            errorCode
          );
        case 500:
          /**
           * Temporary solution until the other parts of the application are ready.
           * Todo: Remove this condition when we enable login for other emails from '@aiphone.com'.
           * **/
          if (responseData?.errorDetails === 'Invalid email address') {
            throw new ApiError(
              'Invalid email address',
              ErrorType.NOT_FOUND,
              responseData.error.type || 'Unknown',
              session
            );
          }
          if (responseData.error && responseData.error.type === 'NewPasswordRequired') {
            throw new ApiError(
              'New password required',
              ErrorType.SERVER,
              responseData.error.type || 'Unknown',
              session
            );
          }

          if (responseData.error && responseData.error.type === 'MFACodeMismatchException') {
            throw new ApiError('', ErrorType.SERVER, responseData.error.type || 'Unknown', session);
          }

          if (responseData.error && responseData.error.type === 'MFANotAuthorizedException') {
            throw new ApiError('', ErrorType.SERVER, responseData.error.type || 'Unknown', session);
          }

          if (responseData.error && responseData.error.type === 'MFAInvalidParameterException') {
            throw new ApiError('', ErrorType.SERVER, responseData.error.type || 'Unknown', session);
          }

          throw new ApiError(
            error.response.data.message || JSON.parse(error.response.data)?.errorDetails || 'Error',
            ErrorType.SERVER_DB,
            errorCode,
            session
          );
        case 501:
          throw new ApiError(
            error.response.data.message || JSON.parse(error.response.data)?.errorDetails || 'Not Implemented',
            ErrorType.EXCLUDED,
            errorCode
          );
        default:
          throw new ApiError(
            error.response.data.message || JSON.parse(error.response.data)?.errorDetails || 'Unknown Error',
            ErrorType.UNEXPECTED,
            errorCode
          );
      }
    } else if (error.code) {
      switch (error.code) {
        case 'ECONNABORTED':
          throw new ApiError(error.message, ErrorType.TIMEOUT);
        case 'ERR_NETWORK':
          throw new ApiError(error.message, ErrorType.NETWORK);
        default:
          throw new ApiError('An unexpected error occurred', ErrorType.UNEXPECTED);
      }
    } else {
      throw new ApiError('An unexpected error occurred', ErrorType.UNEXPECTED);
    }
  } else {
    // If the error isn't an AxiosError, it's something unexpected
    throw new ApiError('An unexpected error occurred', ErrorType.UNEXPECTED);
  }
};

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError;
};
