import { useMutation, UseMutationOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { LatestEula, SaveEulaRequest } from '../components/common/Types';
import { fetchLatestEula, saveEula } from '../lib/api/LicenseManagementApi';

export const useFetchEula = (): UseQueryResult<LatestEula, Error> => {
  return useQuery<LatestEula, Error>({
    queryKey: ['latestEula'],
    queryFn: fetchLatestEula,
    staleTime: 60000,
    refetchOnWindowFocus: false
  });
};

export const useSaveEula = (onSuccessCallback?: () => void, onErrorCallback?: (error: unknown) => void) => {
  const mutationOptions: UseMutationOptions<
    void,
    Error,
    {
      aiphoneBranchPublicId: string;
      deploymentId: string;
      data: SaveEulaRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, data }) => {
      return await saveEula(aiphoneBranchPublicId, deploymentId, data);
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};
